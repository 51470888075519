<template>
  <div class="graph-wrap">
    <svg v-if="type === 'pie'" viewBox="0 0 38 38" class="pie-chart">
      <circle
        class="pie-base"
        cx="19"
        cy="19"
        r="15.9"
        fill="none"
        stroke="#efefef"
        stroke-width="6"
      />
      <circle
        class="pie-main"
        cx="19"
        cy="19"
        r="15.9"
        fill="none"
        stroke="#850491"
        :stroke-width="6"
        :stroke-dasharray="values + ' 100'"
        :style="{ '--dashoffset': values }"
      />
    </svg>
    <svg
      v-else-if="type === 'bar'"
      viewBox="0 0 40 100"
      class="bar-chart"
      style="height: 100%"
    >
      <path class="bar-base" d="M 0 100 H 0 V 0 H 40 L 40 100" fill="#850491" />
      <path
        class="bar-main"
        :d="'M 0 0 H 40 V ' + values + ' H -40 L 0 0'"
        fill="#efefef"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    values: Number,
    type: String
  }
}
</script>

<style lang="scss" scoped>
.graph-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.pie-main {
  --dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: center;
  animation: strokePie ease-in-out 1s;
}

.bar-chart {
  border-radius: 0 16px 16px 0;
}

.bar-main {
  transform-origin: top;
  animation: strokeBar ease-in-out 1s;
}

@keyframes strokePie {
  0% {
    stroke-dashoffset: var(--dashoffset);
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strokeBar {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>
