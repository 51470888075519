var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[(_vm.checkRecipeExist)?_c('div',{staticClass:"caution-wrap"},[_c('texts',{staticClass:"caution-text",attrs:{"text":_vm.$t('customblock.popup.delete.cautionMessage'),"color":"caution","size":"small"}})],1):_vm._e(),_c('div',{staticClass:"items-wrap"},[_c('list-item',{attrs:{"items":_vm.list,"field":_vm.field,"isToggle":""},scopedSlots:_vm._u([{key:"toggleContent",fn:function(ref){
var entry = ref.entry;
return [(entry.description)?_c('div',{staticClass:"title-wrap"},[_c('div',[_c('description',{attrs:{"text":entry.description}})],1)]):_vm._e(),_vm._t("toggleContent",function(){return [_c('div',{staticClass:"block"},[(
                entry.recipes_using != null && entry.recipes_using.length > 0
              )?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"item"},[_c('texts',{staticClass:"title",attrs:{"text":_vm.$t('customblock.popup.delete.usedRecipes'),"size":"small","color":"gray"}}),_c('div',{staticClass:"list"},_vm._l((_vm.limitCount(
                      entry.recipes_using,
                      4
                    )),function(recipe,key){return _c('text-with-icon',{key:key,staticClass:"list-item",attrs:{"text":recipe.name,"iconName":"newTab","pos":"right"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('goto-recipe', {
                        id: recipe.recipe_id,
                        accountId: recipe.account_id
                      })}}})}),1)],1)]):_vm._e(),_c('div',{staticClass:"inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('customblock.blockType'),"text":_vm.$t('customblock.type.' + entry.block_type)}})],1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.owner'),"text":entry.author_name}})],1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.createTime')}},[_c('a-time',{attrs:{"value":entry.createTime}})],1)],1),_c('div',{staticClass:"inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.updateTime')}},[_c('a-time',{attrs:{"value":entry.updateTime}})],1)],1)])]},{"entry":entry})]}}],null,true)})],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"button"},[_c('button-main',{attrs:{"type":"sub","text":_vm.$t('common.cancel'),"isDisabled":_vm.disabledClick},on:{"click":function($event){return _vm.$emit('close-modal')}}})],1),_c('div',{staticClass:"button"},[_c('button-main',{attrs:{"type":"caution","text":_vm.$t('common.deleteButton'),"isDisabled":_vm.disabledClick},on:{"click":_vm.deleteSubmit}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }