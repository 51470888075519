<template>
  <div class="popup-delete-wrap">
    <div>
      <texts
        v-if="target.recipes_using && target.recipes_using.length > 0"
        class="popup-delete-desc"
        :text="$t('customblock.popup.delete.cautionMessage')"
        color="caution"
        size="small"
      />
      <div class="popup-delete-title-wrap">
        <texts :text="target.name" isBold />
      </div>
      <div v-if="target.description" class="popup-delete-description">
        <description :text="target.description" />
      </div>
      <div class="popup-delete-block">
        <div
          v-if="target.recipes_using != null && target.recipes_using.length > 0"
          class="popup-delete-inner"
        >
          <div class="popup-delete-item">
            <texts
              class="popup-delete-title"
              :text="$t('customblock.popup.delete.usedRecipes')"
              size="small"
              color="gray"
            />
            <div class="popup-delete-list">
              <text-with-icon
                v-for="(recipe, key) in limitCount(target.recipes_using, 4)"
                :key="key"
                class="popup-delete-list-item"
                :text="recipe.name"
                iconName="newTab"
                pos="right"
                @click.native.stop="
                  $emit('goto-recipe', {
                    id: recipe.recipe_id,
                    accountId: recipe.account_id
                  })
                "
              />
            </div>
          </div>
        </div>
        <div class="popup-delete-inner">
          <text-with-title
            :title="$t('customblock.blockType')"
            :text="$t('customblock.type.' + target.block_type)"
          />
        </div>
      </div>
      <div class="popup-delete-block">
        <div class="popup-delete-inner">
          <text-with-title
            :title="$t('common.owner')"
            :text="target.author_name"
          />
        </div>
        <!--
          TODO 本来は最終更新者が入るが取得できていない
        <div class="popup-delete-inner">
          <div class="popup-delete-inner">
            <text-with-title
              :title="$t('common.owner')"
              :text="target.author_name"
            />
          </div>
        </div>
        -->
      </div>
      <div class="popup-delete-block">
        <div class="popup-delete-inner">
          <text-with-title :title="$t('common.createTime')">
            <a-time :value="target.created_at" />
          </text-with-title>
        </div>
        <div class="popup-delete-inner">
          <text-with-title :title="$t('common.updateTime')">
            <a-time :value="target.updated_at" />
          </text-with-title>
        </div>
      </div>
      <div class="popup-delete-footer">
        <div class="popup-delete-button-block">
          <div class="popup-delete-button">
            <button-main
              type="sub"
              :text="$t('common.cancel')"
              :isDisabled="disabledClick"
              @click="$emit('cancel-delete')"
            />
          </div>
          <div class="popup-delete-button">
            <button-main
              type="caution"
              :text="$t('datasetList.popup.deleteData.submit')"
              :isDisabled="disabledClick"
              @click="$emit('delete-customblock')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    description,
    buttonMain,
    textWithTitle,
    textWithIcon
  },
  props: {
    target: Object,
    disabledClick: Boolean
  },
  methods: {
    limitCount(arr) {
      if (!arr || arr.length === 0) return []
      if (arr.length > 4) {
        return arr.slice(0, 4)
      } else {
        return arr
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-delete {
  &-wrap {
    display: flex;
    flex-direction: column;
    width: adjustVW(720);
    height: 100%;
  }
  &-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
    margin: 0 0 $space-medium;
  }
  &-description {
    margin-top: -$space-text;
    margin-bottom: $space-medium;
  }
  &-inner {
    overflow: hidden;
    width: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-button {
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
    &-block {
      display: flex;
      justify-content: flex-end;
      margin-top: $space-medium;
    }
  }
  &-title {
    margin-bottom: $space-text;
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin: 0 0 $space-base;
    }
  }
  &-footer {
    margin-top: $space-medium;
  }

  &-list {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-text;
    &-item {
      overflow: hidden;
      width: fit-content;
      max-width: 100%;
      text-decoration: underline;
      cursor: pointer;
      @include buttonHover;
    }
  }
  &-desc {
    margin-bottom: $space-small;
    white-space: pre-line;
  }
}
</style>
