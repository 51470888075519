<template>
  <div>
    <texts
      class="title"
      :text="values.name"
      color="gray"
      size="small"
    />
    <div v-tooltip="errorTips" class="input">
      <MoleculesInputBox
        :value="internalValue"
        :min="values.params.min"
        :max="values.params.max"
        type="number"
        required
        isGray
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import numericParamPrevie from '@/mixin/numeric-param-preview'
import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  components: {
    MoleculesInputBox
  },
  mixins: [numericParamPrevie],
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: null
    }
  },
  watch: {
    'values.params.value'(newVal) {
      this.internalValue = newVal
    }
  },
  mounted() {
    this.internalValue = this.values.params.value
  },
  methods: {
    updateValue(value) {
      const res = JSON.parse(JSON.stringify(this.values))
      let resValue = value
      if (isNaN(value)) {
        resValue = null
      }
      res.params.value = resValue
      this.internalValue = resValue
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-text;
}
</style>
