<template>
  <div>
    <div class="item">
      <div v-tooltip="mixinErrorTips" class="item-half">
        <div>
          <MoleculesInputBox
            v-model="internalValue.value"
            :title="$t('customblock.edit.content.params.detail.default')"
            :min="internalValue.min"
            :max="internalValue.max"
            type="number"
            isGray
            required
            @focus="mixinToggleFocus('default')"
          />
        </div>
      </div>
    </div>
    <div class="item">
      <div v-tooltip.bottom="mixinErrorTipsMin">
        <div>
          <MoleculesInputBox
            v-model="internalValue.min"
            :title="$t('customblock.edit.content.params.detail.min')"
            type="number"
            :max="internalValue.max"
            :error="mixinIsMinLargerMax"
            isGray
            @focus="mixinToggleFocus('min')"
          />
        </div>
      </div>
      <div v-tooltip.bottom="mixinErrorTipsMax">
        <div>
          <MoleculesInputBox
            v-model="internalValue.max"
            :title="$t('customblock.edit.content.params.detail.max')"
            type="number"
            :min="internalValue.min"
            :error="mixinIsMaxSmallerMin"
            isGray
            @focus="mixinToggleFocus('max')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numericParamsSetting from '@/mixin/numeric-params-setting.js'

import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  mixins: [numericParamsSetting],
  components: {
    MoleculesInputBox
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  grid-column-gap: $space-small;
  &:not(:last-of-type) {
    margin-bottom: $space-medium;
  }
  &-half {
    width: calc(50% - #{$space-small / 2});
  }
}
</style>
