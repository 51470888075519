import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object[] } items
 * @param { Object } name
 * @returns { Object }
 **/
export function duplicateValidator(items, name) {
  const keys = Object.keys(items)

  const nameList = keys.length > 0 ? keys.map((id) => items[id].name) : []

  const checkResult = duplicateChecker(nameList, name)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, name)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
