<template>
  <div>
    <texts
      class="title"
      :text="$t('customblock.edit.content.params.detail.initial')"
      size="small"
      color="gray"
    />
    <div class="input">
      <AtomsCheckBox
        v-model="internalValue.value"
        :checked="internalValue.value"
      />
    </div>
  </div>
</template>

<script>
import AtomsCheckBox from '@/components/atoms/checkbox-base'
import texts from '@/components/atoms/text'

export default {
  components: {
    texts,
    AtomsCheckBox
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: adjustVW(20);
}
.input {
  width: fit-content;
}
</style>
