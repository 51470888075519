<template>
  <params-list :params="params" />
</template>

<script>
import ParamsList from './params/params-list'

export default {
  components: {
    ParamsList
  },
  props: {
    params: Object
  }
}
</script>
