export const DEFAULT_PACKAGES = {
  Cython: '0.29.14',
  matplotlib: '3.2.0',
  pandas: '1.2.5',
  joblib: '1.0.1',
  numpy: '1.22.4',
  scipy: '1.4.1',
  'scikit-learn': '1.1.2',
  'scikit-image': '0.19.3',
  statsmodels: '0.11.0',
  tables: '^3.7.0'
}
