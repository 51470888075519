<template>
  <label v-tooltip.left="tips" class="wrap">
    <input
      class="input"
      type="radio"
      :value="text"
      :checked="isChecked"
      :name="name"
      @change="updateValue($event)"
    >
    <div class="input-icon" :class="{ 'input-icon-caution': caution }" />
    <texts
      v-if="!noText"
      :text="displayName ? displayName : text"
      :color="caution ? 'caution' : ''"
    />
  </label>
</template>

<script>
import texts from './text'

export default {
  components: {
    texts
  },
  data() {
    return {
      isChecked: this.checked
    }
  },
  props: {
    /** ラジオボタンに表示するテキストとvalueに代入する値 */
    text: [String, Number],
    /** チェックが入っているか */
    checked: {
      type: Boolean,
      default: false
    },
    /** 注意を表示するかどうか */
    caution: {
      type: Boolean,
      default: false
    },
    /** ラジオボタンの識別用 */
    name: String,
    /** ラジオボタンのみを使用する場合 */
    noText: {
      type: Boolean,
      default: false
    },
    /** ラジオボタンのvalueと異なる値を表示する場合の文字列 */
    displayName: {
      type: String,
      default: null
    },
    /** tooltipを表示する場合Object Objectの中身はv-tooltipに合わせてください Stringのみは不可 */
    tips: {
      type: Object,
      default: null
    }
  },
  methods: {
    updateValue(e) {
      this.isChecked = e.target.checked
      this.$emit('input', this.text)
      this.$emit('change', this.text)
    }
  },
  watch: {
    checked(newVal) {
      this.isChecked = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  user-select: none;
}
.input {
  &-icon {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    width: adjustVW(20);
    height: adjustVW(20);
    margin: 0 $space-sub 0 0;
    background: $line-gray;
    border-radius: 9in;
    box-shadow: 0 0 0 0 transparent inset;
    transition: box-shadow $transition-base;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: adjustVW(12);
      height: adjustVW(12);
      background: $key-color;
      border-radius: 9in;
      opacity: 0;
      z-index: 1;
      transform: translate(-150%, -50%);
      transition: opacity $transition-base, transform $transition-base;
    }
    &-caution {
      &::after {
        background: $text-caution;
      }
    }
  }
  &:checked {
    + .input-icon {
      background: $background;
      box-shadow: $border-radius-emphasis inset;
      &::after {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
    + .input-icon-caution {
      box-shadow: $border-radius-caution inset;
    }
  }
}
</style>
