<!--
  長い文章を表示する
  もっと見るボタンで、文章を開閉できる
-->

<template>
  <div class="c-text-description-long-wrap">
    <div
      class="c-text-description-long-inner"
      :class="{
        'c-text-description-long-more': isMore,
        'c-text-description-long-horizontal': showHorizontal,
        'c-text-description-long-gray': isGray
      }"
      v-text="replaceText(clampText)"
    />
    <button
      v-if="isMore"
      class="c-text-description-long-more-button"
      @click="showDescriptionPopup()"
    >
      <div class="c-text-description-long-more-inner">
        <texts
          :text="$t('common.openDescription')"
          size="small"
          color="text-button"
          isBold
        />
      </div>
    </button>
    <popup
      :title="title"
      :showPopup="showPopup"
      @close-modal="closeDescriptionPopup()"
    >
      <div class="c-text-description-long-popup-body">
        <div class="c-text-description-long-popup" v-text="replaceText(text)" />
      </div>
    </popup>
  </div>
</template>

<script>
import texts from './text'
import { popup } from '@/components/molecules/popup'

export default {
  components: {
    texts,
    popup
  },
  data() {
    return {
      showPopup: false // もっと見るを押下して開いたかどうか
    }
  },
  props: {
    /** ポップアップに表示するタイトル */
    title: String,
    /** 表示する文章。表示文字数の制限は300 */
    text: String,
    /** 水平方向に長い場合。ページタイトル下の説明など、表示文字数の制限は120 */
    showHorizontal: Boolean,
    /** 表示する最大文字数デフォルトは300 */
    maxCount: {
      type: Number,
      default: 300
    },
    /** グレーで表示 */
    isGray: Boolean
  },
  computed: {
    clampText() {
      if (this.showHorizontal && this.text.length > this.maxCount / 2) {
        return (
          this.text.slice(0, this.maxCount / 2 - 30) + '...' + '\x20\x20\x20'
        ) // 余白用に空白文字を入れています
      } else if (this.text?.length > this.maxCount) {
        return this.text.slice(0, this.maxCount) + '...' + '\x20\x20\x20' // 余白用に空白文字を入れています
      } else {
        return this.text
      }
    },
    isMore() {
      if (this.showHorizontal) {
        return this.text.length > this.maxCount / 2
      }
      return this.text?.length > this.maxCount
    }
  },
  methods: {
    replaceText(content) {
      // 文章内の改行コードを変換
      if (!content) return content
      content.replace(/\n/g, '\\n')
      return content.replace(/\\n/g, '\n')
    },
    showDescriptionPopup() {
      // 詳しく読むのポップアップを表示
      this.showPopup = true
    },
    closeDescriptionPopup() {
      // 詳しく読むのポップアップを閉じる
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-text-description-long {
  &-wrap {
    position: relative;
  }
  &-inner {
    overflow: hidden;
    display: inline;
    line-height: $line-height-base;
    color: $text-main;
    font-size: $text-base;
    word-break: break-all;
    white-space: pre-line;
  }
  &-more {
    &-button {
      vertical-align: bottom;
    }
    &-inner {
      transition: $transition-base;
      .c-text-description-long-more-button:hover & {
        opacity: 0.3;
      }
    }
  }
  &-horizontal {
    word-break: break-all;
    white-space: normal;
  }
  &-gray {
    color: $text-sub;
  }
  &-popup {
    display: contents;
    width: min-content;
    max-width: 100%;
    height: 100%;
    line-height: $line-height-large;
    color: $text-main;
    font-size: $text-base;
    overflow-wrap: break-word;
    white-space: pre-line;
    &-body {
      overflow-y: auto;
      padding-right: $space-sub;
      @include scrollbar;
    }
  }
}
</style>
