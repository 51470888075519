<template>
  <div>
    <MoleculesTransitionToggleContents>
      <template v-if="settedValues">
        <component
          :is="selectedTypeComponet"
          :values="internalValue"
          @input="updateValue"
          @toggle-varidated-test="$emit('toggle-varidated-test', $event)"
        />
      </template>
    </MoleculesTransitionToggleContents>
  </div>
</template>

<script>
import boolPreview from './bool.vue'
import checkboxPreview from './checkbox.vue'
import intPreview from './int.vue'
import numberSelectPreview from './number_select.vue'
import numberPreview from './number.vue'
import selectPreview from './select.vue'
import textPreview from './text.vue'
import MoleculesTransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    boolPreview,
    checkboxPreview,
    intPreview,
    numberSelectPreview,
    numberPreview,
    selectPreview,
    textPreview,
    MoleculesTransitionToggleContents
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: {},
      settedValues: false
    }
  },
  computed: {
    selectedTypeComponet() {
      if (this.value.type === 'number_select') {
        return 'numberSelectPreview'
      }
      return this.value.type + 'Preview'
    }
  },
  watch: {
    value: {
      handler(value) {
        this.internalValue = value
      },
      deep: true
    }
  },
  mounted() {
    this.settedValues = false
    this.internalValue = this.value
    this.$nextTick(() => {
      this.settedValues = true
    })
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
