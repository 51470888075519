<template>
  <div>
    <div class="default-value">
      <div class="title">
        <texts
          color="gray"
          :text="$t('customblock.edit.content.params.detail.default')"
          size="small"
        />
      </div>
      <div>
        <MoleculesSelectBox
          v-model="internalValue.value"
          :items="selectBoxItems"
          isGray
        />
      </div>
    </div>
    <div>
      <div
        v-for="(option, index) in internalValue.options"
        :key="index"
        class="item"
      >
        <div v-tooltip="duplicateTips(index, 'text')">
          <MoleculesInputBox
            v-model="internalValue.options[index].text"
            :title="
              $t('customblock.edit.content.params.detail.displayName') +
                (index + 1)
            "
            required
            isGray
          />
        </div>
        <div v-tooltip="duplicateTips(index, 'value')">
          <div class="title">
            <texts
              size="small"
              color="gray"
              :text="
                $t('customblock.edit.content.params.detail.value') + (index + 1)
              "
            />
            <div
              v-if="internalValue.options.length > 1 && deleteTarget !== index"
              class="item-icon"
            >
              <AtomsIcons
                color="caution"
                iconName="delete"
                isButton
                size="small"
                @icon-click="checkDelete(index)"
              />
            </div>
            <div v-else class="item-icon" />
          </div>
          <div>
            <MoleculesInputBox
              :value="internalValue.options[index].value"
              required
              isGray
              @input="inputValue($event, index)"
            />
          </div>
        </div>
        <MoleculesDeleteParams
          :isShow="deleteTarget === index"
          @confirm-delete="confirmDelete(index)"
          @discard-delete="discardDelete"
        />
      </div>
      <button
        class="item-add"
        :class="{
          'item-disabled': disabledAdd
        }"
        :disabled="disabledAdd"
        @click="disabledAdd ? '' : addBox()"
      >
        <MoleculesTextWithIcon
          size="small"
          icon-name="plus"
          :text="$t('customblock.edit.content.params.detail.add')"
          :color="disabledAdd ? 'gray' : 'black'"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { duplicateObjectValueCheck } from '@/lib/misc'

import AtomsIcons from '@/components/atoms/icon'
import MoleculesInputBox from '@/components/molecules/input-box'
import MoleculesSelectBox from '@/components/molecules/select-box'
import MoleculesDeleteParams from '@/components/molecules/delete-params/delete-params'
import MoleculesTextWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    AtomsIcons,
    MoleculesInputBox,
    MoleculesSelectBox,
    MoleculesDeleteParams,
    MoleculesTextWithIcon
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      deleteTarget: null,
      selectedIndex: 0
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    },
    disabledAdd() {
      return this.internalValue.options.some((option) => {
        return !option.value || !option.text
      })
    },
    selectBoxItems() {
      return this.internalValue.options.flatMap((option) => {
        if (!option.value) {
          return []
        }
        return [option]
      })
    }
  },
  watch: {
    'internalValue.value'(newVal) {
      const targetIndex = this.internalValue.options.findIndex((option) => {
        return option.value === newVal
      })
      this.selectedIndex = targetIndex >= 0 ? targetIndex : 0
      if (!newVal) {
        this.$emit('set-error', true)
      } else {
        this.$emit('set-error', false)
      }
    },
    'internalValue.options': {
      handler(newVal) {
        const checkError = newVal.some((option) => {
          return !option.value || !option.text
        })
        const checkDuplicateText = duplicateObjectValueCheck(newVal, 'text')
        const checkDuplicateValue = duplicateObjectValueCheck(newVal, 'value')
        const error = checkError || checkDuplicateText || checkDuplicateValue
        this.$emit('set-error', error)
      },
      deep: true
    }
  },
  methods: {
    checkDelete(index) {
      this.deleteTarget = index
    },
    confirmDelete(index) {
      if (index === this.selectedIndex) {
        this.selectedIndex = 0
      } else if (index < this.selectedIndex) {
        this.selectedIndex = this.selectedIndex - 1
      }
      this.internalValue.options.splice(index, 1)
      this.internalValue.value =
        this.internalValue.options[this.selectedIndex].value
      this.deleteTarget = null
    },
    discardDelete() {
      this.deleteTarget = null
    },
    addBox() {
      console.log('test')
      const defaultIndex = this.internalValue.options.length + 1
      this.internalValue.options.push({
        value: 'default_' + defaultIndex,
        text:
          this.$t('customblock.edit.content.params.detail.defaultValueText') +
          defaultIndex,
        check: false
      })
    },
    inputValue(value, index) {
      this.internalValue.options[index].value = value
      if (index === this.selectedIndex) {
        this.internalValue.value = value
      }
    },
    duplicateTips(index, type) {
      const target = this.internalValue.options[index][type]
      const check = this.internalValue.options.some((option, optionIndex) => {
        return (
          option[type] === target &&
          index !== optionIndex &&
          index > optionIndex
        )
      })
      return {
        content: this.$t(
          'customblock.edit.content.params.detail.isNoDuplicate'
        ),
        trigger: 'manual',
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 },
        show: check
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: $space-small;
  margin-bottom: $space-base;
}
.default-value {
  position: relative;
  width: 100%;
  margin-bottom: $space-medium;
  z-index: 10;
}
.input {
  width: fit-content;
}
.item {
  display: flex;
  align-items: center;
  grid-column-gap: $space-small;
  &:not(:last-of-type) {
    padding-bottom: $space-small;
    margin-bottom: $space-sub;
    border-bottom: $border-sub;
  }
  &-add {
    width: fit-content;
    margin-top: $space-medium;
    cursor: pointer;
    @include buttonHover;
  }
  &-disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
