<template>
  <div v-if="settedParams" class="wrap">
    <div class="params">
      <div class="params-inner">
        <div class="params-body">
          <div class="params-input">
            <MoleculesInputBox
              v-model="internalValue.name"
              :title="$t('customblock.edit.content.params.detail.displayName')"
              :required="firstEditName"
              isGray
            />
          </div>
          <div v-tooltip="valiableNameDuplicate" class="params-input">
            <MoleculesInputBox
              v-model="internalValue.variableName"
              :title="$t('customblock.edit.content.params.detail.veriable')"
              :required="firstEditKey"
              isGray
            />
          </div>
        </div>
        <div class="params-select">
          <texts
            class="params-title"
            :text="$t('customblock.edit.content.params.detail.format')"
            color="gray"
            size="small"
          />
          <div class="params-input">
            <MoleculesSelectBox
              v-model="internalValue.type"
              :items="selectItemKeys"
              is-different-text-value
              isGray
            />
          </div>
        </div>
        <div class="params-main">
          <MoleculesTransitionToggleContents>
            <div v-if="!inSetParams">
              <component
                :is="selectedTypeComponet"
                v-if="internalValue"
                v-model="internalValue.params"
                @set-error="setError"
              />
            </div>
          </MoleculesTransitionToggleContents>
        </div>
      </div>
      <div class="params-button">
        <AtomsButtonMain
          :text="$t('common.cancel')"
          type="sub"
          @click="cancelEdit"
        />
        <AtomsButtonMain
          :text="$t('customblock.edit.content.params.detail.save')"
          type="emphasis"
          :is-disabled="disabledSave"
          @click="$emit('save-params', internalValue)"
        />
      </div>
    </div>
    <div class="preview">
      <OrganismsEditPreviewWrapper :values="internalValue" />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import AtomsButtonMain from '@/components/atoms/button-main'
import AtomsIcons from '@/components/atoms/icon'
import AtomsCheckBox from '@/components/atoms/checkbox-base'
import MoleculesInputBox from '@/components/molecules/input-box'
import MoleculesSelectBox from '@/components/molecules/select-box'
import MoleculesTextWithIcon from '@/components/molecules/text-with-icon'
import MoleculesTransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import OrganismsEditPreviewWrapper from '../edit-preview/wrapper'

import boolInput from './bool.vue'
import checkboxInput from './checkbox.vue'
import intInput from './int.vue'
import numberSelectInput from './number_select.vue'
import numberInput from './number.vue'
import selectInput from './select.vue'
import textInput from './text.vue'

import { PARAMS_KEYS, paramsTemplate } from '@/lib/customblockVariables.js'

export default {
  components: {
    texts,
    boolInput,
    checkboxInput,
    intInput,
    numberSelectInput,
    numberInput,
    selectInput,
    textInput,
    AtomsButtonMain,
    AtomsIcons,
    AtomsCheckBox,
    MoleculesInputBox,
    MoleculesSelectBox,
    MoleculesTextWithIcon,
    MoleculesTransitionToggleContents,
    OrganismsEditPreviewWrapper
  },
  props: {
    isNew: Boolean,
    editVariableName: String,
    value: {
      type: Object,
      default: null
    },
    validateOk: Boolean,
    validateValiableName: Boolean
  },
  data() {
    return {
      internalValue: {},
      cancelValue: null,
      settedParams: false,
      inSetParams: false,
      isError: false,
      firstEditName: false,
      firstEditKey: false
    }
  },
  computed: {
    selectItemKeys() {
      return Object.values(PARAMS_KEYS).map((value) => {
        return {
          value,
          text: this.$t('customblock.edit.paramsKeys.' + value)
        }
      })
    },
    selectedTypeComponet() {
      if (!this.internalValue?.type) return ''
      if (this.internalValue.type === 'number_select') {
        return 'numberSelectInput'
      }
      return this.internalValue.type + 'Input'
    },
    disabledSave() {
      if (!this.validateOk) {
        return true
      }
      if (!this.internalValue.name || !this.internalValue.variableName)
        return true
      return this.isError
    },
    valiableNameDuplicate() {
      if (this.validateValiableName) return null
      return {
        content: this.$t('customblock.error.valiableDuplicate'),
        trigger: 'manual',
        hideOnTargetClick: false,
        delay: { show: 300, hide: 300 },
        show: true
      }
    }
  },
  watch: {
    'internalValue.type'(newVal) {
      if (!this.settedParams) return
      this.inSetParams = true
      this.internalValue.params = JSON.parse(
        JSON.stringify(paramsTemplate[newVal])
      )
      this.$nextTick(() => {
        this.inSetParams = false
      })
    },
    'internalValue.name'(value) {
      if (value && !this.firstEditName) {
        this.firstEditName = true
      }
    },
    'internalValue.variableName'(value) {
      if (value && !this.firstEditKey) {
        this.firstEditKey = true
      }
      this.$emit('validate-valiable-name', value)
    }
  },
  mounted() {
    this.settedParams = false
    if (!this.isNew) {
      const value = this.value
      this.internalValue = JSON.parse(JSON.stringify(value))
      this.internalValue.variableName = this.editVariableName
      this.cancelValue = JSON.parse(JSON.stringify(this.internalValue))
    } else {
      const defaultParams = {
        name: '',
        variableName: '',
        type: PARAMS_KEYS.INT,
        params: JSON.parse(JSON.stringify(paramsTemplate[PARAMS_KEYS.INT]))
      }
      this.internalValue = defaultParams
    }
    this.$nextTick(() => {
      this.settedParams = true
    })
  },
  methods: {
    cancelEdit() {
      this.internalValue = this.cancelValue
      this.$emit('cancel-edit', this.cancelValue)
    },
    setError(bool) {
      this.isError = bool
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: grid;
  grid-template-columns: 1fr adjustVW(304);
  grid-column-gap: $space-base;
  height: 100%;
}
.params {
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  height: 100%;
  padding: $space-small;
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-title {
    margin-bottom: $space-base;
  }
  &-input {
    width: 100%;
  }
  &-body {
    display: flex;
    grid-column-gap: $space-small;
    margin-bottom: $space-medium;
  }
  &-select {
    position: relative;
    width: calc(50% - #{$space-small / 2});
    margin-bottom: $space-medium;
    z-index: 11;
  }
  &-button {
    display: grid;
    grid-template-columns: adjustVW(184) 1fr;
    grid-column-gap: $space-small;
  }
  &-main {
    overflow-x: hidden;
    overflow-y: scroll;
    flex-shrink: 1;
    height: 100%;
    padding-right: $space-base;
    margin-bottom: $space-medium;
    @include scrollbar;
  }
}
.preview {
  height: 100%;
}
</style>
