<template>
  <div class="list">
    <router-link
      v-if="
        clipObject &&
          clipObject.length > 0 &&
          predictionColumns &&
          predictionColumns.length > 1 &&
          id
      "
      class="list-link"
      :to="{
        name: 'trainedAiProjectDetail',
        params: {
          id: id
        }
      }"
    >
      <text-with-icon
        :text="$t('trainedAi.multi.featureImportanceLink')"
        class="list-link-item"
        size="small"
        color="link"
        iconName="forward"
        pos="right"
      />
    </router-link>
    <template v-else-if="clipObject && clipObject.length > 0">
      <template v-for="(list, index) in clipObject">
        <bullet-item
          :key="index"
          class="list-bullet"
          :text="list"
          size="small"
        />
      </template>
    </template>
    <div v-else>
      <texts
        class="list-none"
        :text="$t('trainedAiDetails.noFeatureImportance')"
        size="min"
        color="gray"
      />
    </div>
  </div>
</template>

<script>
import bulletItem from '@/components/atoms/bullet-item'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    bulletItem,
    textWithIcon
  },
  props: {
    id: String,
    predictionColumns: Array,
    featureImportance: Object
  },
  computed: {
    clipObject() {
      if (
        !this.featureImportance ||
        Object.keys(this.featureImportance).length === 0
      )
        return []
      const keyList = Object.keys(this.featureImportance)
      const clipObject = []
      for (const key in keyList) {
        if (key > 2) {
          return clipObject
        } else {
          clipObject.push(keyList[key])
        }
      }
      return clipObject
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-text;
  &-link {
    display: block;
    width: fit-content;
    &-item {
      width: fit-content;
    }
  }
  &-bullet {
    font-size: 1.2rem;
  }
  &-none {
    word-break: break-all;
    white-space: pre-line;
  }
}
</style>
