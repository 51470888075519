var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[(
      _vm.clipObject &&
        _vm.clipObject.length > 0 &&
        _vm.predictionColumns &&
        _vm.predictionColumns.length > 1 &&
        _vm.id
    )?_c('router-link',{staticClass:"list-link",attrs:{"to":{
      name: 'trainedAiProjectDetail',
      params: {
        id: _vm.id
      }
    }}},[_c('text-with-icon',{staticClass:"list-link-item",attrs:{"text":_vm.$t('trainedAi.multi.featureImportanceLink'),"size":"small","color":"link","iconName":"forward","pos":"right"}})],1):(_vm.clipObject && _vm.clipObject.length > 0)?[_vm._l((_vm.clipObject),function(list,index){return [_c('bullet-item',{key:index,staticClass:"list-bullet",attrs:{"text":list,"size":"small"}})]})]:_c('div',[_c('texts',{staticClass:"list-none",attrs:{"text":_vm.$t('trainedAiDetails.noFeatureImportance'),"size":"min","color":"gray"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }