<template>
  <div class="limit-wrap">
    <!-- エラーが起きた時のリンクがある説明文が入ります。-->
    <div v-if="describeOnly">
      <texts v-if="description" class="limit-text" :text="description" />
      <slot v-else />
    </div>
    <!-- エラーが起きた時のリンクがない説明文が入ります。-->
    <div v-else>
      <texts v-if="description" class="limit-text" :text="description" />
      <slot v-else />
      <button-main
        v-if="okButton"
        :text="okButton"
        type="emphasis"
        :disabled="disableClick"
        @click="$emit('ok')"
      />
      <button-main
        v-if="cancelButton"
        :text="cancelButton"
        type="sub"
        :disabled="disableClick"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    buttonMain
  },
  props: {
    /** 押下不可にする */
    disableClick: Boolean,
    /** エラー分のみをpopupに表示したい場合はtrueにします。 */
    describeOnly: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /** describeOnlyがfalseの時に現れるボタン1 */
    okButton: {
      type: String,
      default: ''
    },
    /** describeOnlyがfalseの時に現れるボタン2 */
    cancelButton: {
      type: String,
      default: ''
    },
    /** エラーの説明分 */
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.limit {
  &-wrap {
    min-width: adjustVW(768 - 48);
  }
  &-text {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
}
</style>
