<template>
  <div
    class="c-menu-box"
    :class="[
      { open: open },
      { 'c-menu-box-left': isLeft },
      { 'c-menu-box-bottom': isBottom }
    ]"
  >
    <button
      v-for="menu in menus"
      :key="menu.id"
      class="c-menu-box-item"
      :disabled="menu.disabled"
      :class="{ 'c-menu-box-item-disabled': menu.disabled }"
      @click="menuClick(menu.value)"
    >
      <text-with-icon
        v-tooltip="disabledTips(menu)"
        :iconName="menu.icon"
        :text="menu.name"
        size="small"
      />
    </button>
  </div>
</template>

<script>
import textWithIcon from './text-with-icon.vue'

export default {
  components: {
    textWithIcon
  },
  props: {
    /** 表示するメニュー id: id, name: 表示する名前, value: 押されたときにemitする値, disabled: 使用不可状態, icon: 表示するアイコン */
    menus: Array,
    /** メニューが開いているかどうか */
    open: Boolean,
    /** メニューを左側に表示 */
    isLeft: Boolean,
    /** メニューを下側に表示 */
    isBottom: Boolean,
    /** メニュー外をクリックするとメニューが閉じるが、そのメニューの内部の範囲で追加したい範囲を$refで指定して渡す */
    menuButton: Array
  },
  data() {
    return { showing: false }
  },
  methods: {
    menuClick(value) {
      this.$emit('close')
      this.$emit('menu-click', { activeMenu: value })
    },
    outerClick(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    },
    disabledTips(menu) {
      if (!menu?.disabled || !menu?.disabledTips) return null
      return {
        content: menu.disabledTips,
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    }
  },
  mounted() {
    this.outerClick(
      window,
      'click',
      function (e) {
        if (
          !this.$el.contains(e.target) &&
          !this.menuButton[0].contains(e.target)
        ) {
          this.$emit('close')
        }
      }.bind(this)
    )
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-menu-box {
  position: absolute;
  bottom: 0;
  left: 100%;
  overflow: hidden;
  width: auto;
  padding: $space-sub 0;
  background: $background;
  border-radius: adjustVW(8);
  box-shadow: $box-shadow-hover;
  opacity: 0;
  z-index: $z-menu-box;
  transform: scaleX(0) translateX(-20px);
  transform-origin: left;
  transition: $transition-base;
  &.open {
    opacity: 1;
    transform: scaleX(1) translateX(0);
    animation: 0.2s ease-in-out openMenu;
  }
  &-item {
    display: block;
    width: 100%;
    padding: $space-small;
    margin: 0 0 $space-sub;
    text-align: left;
    opacity: 0;
    transform: translateX(-100%);
    &:last-child {
      margin: 0;
      border: none;
    }
    &:hover {
      background: $background-sub;
      opacity: 1;
    }
    .open & {
      opacity: 1;
      transform: translateX(0);
      animation: 0.2s ease-in-out openMenuList;
    }
    &-disabled {
      cursor: not-allowed;
      &:hover {
        background: transparent;
      }
      .open & {
        opacity: 0.4;
      }
    }
  }
  &-left {
    right: calc(100% + #{$space-small});
    left: inherit;
    transform-origin: right;
    &-item {
      transform: translateX(100%);
    }
  }
  &-bottom {
    top: 0;
    bottom: inherit;
  }
}
@keyframes openMenu {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}
@keyframes openMenuList {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
