<template>
  <div class="param-panel">
    <div class="title">
      <texts :text="$t('customblock.parameter')" size="large" />
    </div>
    <div class="param-scroll">
      <params-item
        v-for="(item, name) in params"
        :key="name"
        class="param-item"
        :name="name"
        :item="item"
        @edit="$emit('edit', name)"
        @remove="$emit('remove', name)"
      />
    </div>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import paramsItem from './params-item'

export default {
  components: {
    texts,
    paramsItem
  },
  props: {
    params: Object
  }
}
</script>

<style lang="scss" scoped>
.param {
  &-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small $space-base $space-small $space-small;
  }
  &-scroll {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
}
.title {
  flex-shrink: 0;
  margin-bottom: $space-sub;
}
</style>
