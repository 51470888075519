<template>
  <div class="menu-list">
    <template v-for="menu in menus">
      <div
        v-if="menu.value !== 'menu'"
        :key="menu.index"
        class="menu-button-wrap"
      >
        <text-button
          v-tooltip="{
            content: tipsContents(menu),
            trigger: menu.disabled ? 'hover' : 'manual',
            show: menu.tips && activeMenu === menu.value && tipsFlag,
            autoHide: false,
            delay: { show: 200, hide: 200 }
          }"
          class="menu-item"
          :text="menu.name"
          :iconName="menu.iconName"
          :isDisabled="menu.disabled"
          @click="
            $emit('menu-click', { activeMenu: menu.value }),
            menuClick(menu.value)
          "
        />
      </div>
      <div v-else :key="menu.index" class="menu-icon-wrap">
        <button
          ref="menuButton"
          class="menu-icon"
          @click="
            $emit('menu-click', { activeMenu: 'menu' }), menuClick('menu')
          "
        >
          <icons iconName="menu" size="small" />
        </button>
        <menu-box
          v-if="activeMenu === 'menu'"
          :menus="menu.menu"
          :open="activeMenu === 'menu'"
          :menuButton="$refs.menuButton"
          isLeft
          isBottom
          @menu-click="
            $emit('menu-click', { activeMenu: $event.activeMenu }),
            menuClick($event.activeMenu)
          "
          @close="menuClick('')"
        />
      </div>
    </template>
  </div>
  <!--menu-->
</template>

<script>
import icons from '@/components/atoms/icon'
import textButton from '@/components/atoms/text-button'
import menuBox from './menu-box'
export default {
  components: {
    icons,
    textButton,
    menuBox
  },
  data() {
    return {
      activeMenu: null, // 現在選択しているメニュー
      tipsFlag: false
    }
  },
  props: {
    /**
     * id: id
     * name: 表示するボタン名
     * value: emitする値
     * iconName: 表示するアイコン
     * tips: クリック時に表示されるツールチップ
     * tips.name: ツールチップに表示するテキスト
     * disabled: 使用不可状態
     * disabledTips: 使用不可状態のときにその理由を説明するツールチップ
     * menu: 3点リードのメニュー内に表示するボタンの配列
     *  id: id
     *  name: 3点リードのメニュー内のボタン名
     *  icon: 表示するアイコン
     */
    menus: Array
  },
  methods: {
    menuClick(name) {
      window.clearTimeout(this.timer)
      this.tipsFlag = true
      this.activeMenu = name
      this.timer = window.setTimeout(
        function () {
          this.tipsFlag = false
        }.bind(this),
        5000
      )
    },
    tipsContents(menu) {
      if (menu?.disabled) {
        return menu.disabledTips
      } else if (menu?.tips) {
        return menu.tips.name
      } else {
        return null
      }
    }
  },
  destroyed() {
    window.clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &-list {
    display: flex;
    align-items: center;
  }
  &-item {
    margin: 0 $space-base 0 0;
  }
  &-icon {
    padding: $space-sub;
    border-radius: 9in;
    transition: $transition-base;
    &:hover {
      background: $medium-gray;
      opacity: 1;
    }
    &:focus {
      background: $background-text-button;
    }
    &-wrap {
      position: relative;
    }
  }
  &-button-wrap {
    position: relative;
  }
}
</style>
