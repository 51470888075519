<template>
  <div class="bottom-panel-wrap">
    <codeBox
      class="bottom-panel-codebox"
      :code="code"
      @update:code="$emit('update:code', $event)"
    />
    <params
      class="bottom-panel-params"
      :params="params"
      :requirements="requirements"
      @remove-param="$emit('remove-param', $event)"
      @update-param="$emit('update-param', $event)"
    />
  </div>
</template>

<script>
import params from './right-side/params'
import codeBox from './code'

export default {
  components: {
    params,
    codeBox
  },
  props: {
    requirements: Object,
    params: Object,
    code: String
  }
}
</script>

<style lang="scss" scoped>
.bottom-panel {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-areas: 'codebox params';
    grid-template-columns: auto min-content;
    grid-template-rows: 100%;
    height: 100%;
    margin: 0;
    border: adjustVW(2) solid $white;
    background-color: #f4f8ff;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-codebox {
    grid-area: codebox;
    height: 100%;
    margin: 0;
  }
  &-params {
    grid-area: params;
    height: 100%;
    margin: 0;
  }
}
</style>
