<template>
  <div class="customblock-detail-wrap">
    <top
      class="customblock-detail-top"
      :detail="detail"
      :writable="writable"
      @menu-click="$emit('menu-click', $event)"
      @edit-customblock="$emit('edit-customblock', $event)"
    />
    <left
      class="customblock-detail-table"
      :detail="detail"
      :loading="loading"
      :history="history"
      :historyLoading="historyLoading"
      @version-click="$emit('version-click', $event)"
    />
    <right
      class="customblock-detail-side"
      :detail="detail"
      :writable="writable"
      :validatorState="validatorState"
      @input-edit-form="$emit('input-edit-form', $event)"
      @save-customblock="$emit('save-customblock', $event)"
    />
  </div>
</template>

<script>
import top from './top'
import left from './left'
import right from './right'

export default {
  components: {
    top,
    left,
    right
  },
  props: {
    detail: Object,
    history: Array,

    loading: Boolean,
    historyLoading: Boolean,

    writable: Boolean,
    validatorState: Object
  }
}
</script>

<style lang="scss" scoped>
.dataset-list {
  &-block {
    position: relative;
    height: 100%;
  }
  &-list {
    height: 100%;
  }
}

.customblock-detail {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'top top'
      'main side';
    grid-template-columns: 1fr adjustVW(384);
    grid-template-rows: auto minmax(0, 1fr);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-base;
    height: 100vh;
    padding: 0 $space-medium $space-medium;
  }

  &-top {
    grid-area: top;
  }

  &-table {
    grid-area: main;
    height: 100%;
  }

  &-right {
    grid-area: side;
  }
}
</style>
