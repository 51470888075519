<template>
  <div
    class="list"
    :class="{
      'list-vertical': vertical,
      'list-vertical-no-border': noBorder && vertical
    }"
  >
    <template v-if="sliceConditions.length > 0">
      <optimization-conditions-item
        v-for="(target, index) in sliceConditions"
        :key="index"
        class="item"
        :conditionsItem="target"
        :maxWidth="168"
        :vertical="vertical"
      />
      <texts
        v-if="limitCount > 0"
        class="list-others"
        :text="
          $t('trainedAi.optimization.conditions.others', {
            limitCount: limitCount
          })
        "
        size="small"
        color="gray"
      />
    </template>
    <texts
      v-else
      class="list-none-text"
      :text="$t('trainedAi.optimization.conditions.noneList')"
      size="small"
      color="gray"
    />
  </div>
</template>

<script>
import optimizationConditionsItem from '@/components/organisms/optimization/optimization-conditions-item'

export default {
  components: {
    optimizationConditionsItem
  },
  props: {
    optimizationConditions: Object,
    limit: {
      type: Number,
      default: 5
    },
    vertical: Boolean,
    noBorder: Boolean
  },
  computed: {
    columnConditions() {
      if (!this.optimizationConditions?.conditions?.columnConditions) return []
      return this.optimizationConditions.conditions.columnConditions
    },
    expressions() {
      if (!this.optimizationConditions?.conditions?.expressions) return []
      return this.optimizationConditions.conditions.expressions
    },
    filteredColumnConditions() {
      if (!this.columnConditions || this.columnConditions.length === 0)
        return []
      return this.columnConditions.filter((item) => {
        return item?.condition?.type || item?.integer
      })
    },
    sliceConditions() {
      if (!this.filteredColumnConditions || !this.expressions) return []
      const fix = [...this.filteredColumnConditions, ...this.expressions]
      if (this.limitCount >= 0) {
        return fix.slice(0, this.limit)
      } else {
        return fix
      }
    },
    limitCount() {
      if (!this.filteredColumnConditions || !this.expressions) return -1
      if (this.limit >= 0) {
        return (
          this.filteredColumnConditions.length +
          this.expressions.length -
          this.limit
        )
      } else {
        return -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-sub;
  &-vertical {
    flex-direction: column;
    align-items: flex-start;
    .item {
      width: 100%;
      padding-bottom: $space-sub;
      border-bottom: $border-sub;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &-no-border {
      .item {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &-others {
    flex-shrink: 0;
  }
  &-none-text {
    white-space: pre-line;
  }
}
</style>
