<template>
  <!--
    カスタムブロック編集画面の上部
    @click="$emit('save-click')" カスタムブロックの保存
  -->
  <page-top
    :title="title"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="top-function-wrap">
      <button-main
        class="top-function-button"
        :text="$t('customblock.button.saveCustomBlock')"
        type="sub"
        @click="$emit('save-click')"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    pageTop,
    buttonMain
  },
  props: {
    detail: Object,
    blockType: String
  },
  computed: {
    title() {
      if (this.detail.name) {
        return this.$t('customblock.edit.title.editting', {
          name: this.detail.name
        })
      } else {
        return this.$t('customblock.edit.title.creating')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    display: flex;
    align-items: center;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-menu {
    width: adjustVW(204);
    padding: 0;
  }
}
</style>
