<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disabledClick || showPopup.includes('freePlan')"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #delete>
      <popup-delete
        :targets="deleteBlock.targets"
        :disabledClick="disabledClick"
        @cancel-delete="$emit('close-modal', 'delete')"
        @delete-customblock="$emit('delete-customblock', $event)"
        @goto-recipe="$emit('goto-recipe', $event)"
      />
    </template>
    <template #freePlan>
      <popup-free-plan />
    </template>
  </popup-array>
</template>

<script>
import PopupDelete from './popup/delete.vue'
import PopupFreePlan from './popup/free-plan.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    PopupArray,
    PopupDelete,
    PopupFreePlan
  },
  props: {
    showPopup: Array,
    deleteBlock: Object,
    disabledClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        delete: {
          title: this.$t('customblock.popup.delete.title')
        },
        freePlan: {
          title: this.$t('customblock.popup.freePlan.title'),
          noClose: true
        }
      }
    }
  }
}
</script>
