// organisms/edit-params用の設定

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: {},
      mixinCheckMin: false,
      mixinCheckMax: false,
      mixinIsMaxSmallerMin: false,
      mixinIsMinLargerMax: false,
      mixinFocusTarget: null
    }
  },
  computed: {
    mixinErrorTips() {
      const base = {
        content: '',
        trigger: 'manual',
        show:
          (this.mixinCheckMin || this.mixinCheckMax) &&
          this.mixinFocusTarget === 'default' &&
          !this?.showTips?.value?.show,
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 }
      }
      if (this.mixinCheckMin) {
        base.content =
          this.$t('customblock.edit.range.maxSmallerMin') +
          this.$t('customblock.edit.range.minSmallerMin', {
            min: this.internalValue.min
          })
      } else if (this.mixinCheckMax) {
        base.content =
          this.$t('customblock.edit.range.minLargerMax') +
          this.$t('customblock.edit.range.maxLargerMax', {
            max: this.internalValue.max
          })
      }
      return base
    },
    mixinErrorTipsMin() {
      return {
        content:
          this.$t('customblock.edit.range.minLargerMax') +
          this.$t('customblock.edit.range.maxLargerMax', {
            max: this.internalValue.max
          }),
        trigger: 'manual',
        show:
          this.mixinIsMinLargerMax &&
          this.mixinFocusTarget === 'min' &&
          !this?.showTips?.min?.show,
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 }
      }
    },
    mixinErrorTipsMax() {
      return {
        content:
          this.$t('customblock.edit.range.maxSmallerMin') +
          this.$t('customblock.edit.range.minSmallerMin', {
            min: this.internalValue.min
          }),
        trigger: 'manual',
        show:
          this.mixinIsMaxSmallerMin &&
          this.mixinFocusTarget === 'max' &&
          !this?.showTips?.max?.show,
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 }
      }
    }
  },
  watch: {
    internalValue: {
      handler(newVal) {
        this.mixinCheckMin = false
        this.mixinCheckMax = false
        this.mixinIsMaxSmallerMin = false
        this.mixinIsMinLargerMax = false
        let check = isNaN(newVal.value)
        const hasExtra = newVal?.extra_selections
        if (check && hasExtra) {
          check = !newVal.extra_selections.includes(newVal.value)
        } else {
          if (newVal.min) {
            this.mixinCheckMin = !isNaN(newVal.min) && newVal.value < newVal.min
          }
          if (newVal.max) {
            this.mixinCheckMax = !isNaN(newVal.max) && newVal.value > newVal.max
          }
          this.mixinIsMaxSmallerMin = newVal.min > newVal.max
          this.mixinIsMinLargerMax = newVal.max < newVal.min
          check =
            this.mixinCheckMin ||
            this.checkMax ||
            this.mixinIsMaxSmallerMin ||
            this.mixinIsMinLargerMax
        }
        if (hasExtra) {
          const extarCheck = newVal.extra_selections.some((item) => !item)
          if (extarCheck) {
            check = extarCheck
          }
        }
        this.$emit('set-error', check)
      },
      deep: true
    }
  },
  methods: {
    mixinToggleFocus(target) {
      this.mixinFocusTarget = target
    }
  },
  mounted() {
    this.internalValue = this.value
  }
}
