<template>
  <div
    class="trained-ai-accuracy-item"
    :class="{
      'trained-ai-accuracy-item-card': isCard,
      'trained-ai-accuracy-item-min-title': isMinTitle
    }"
  >
    <div class="trained-ai-accuracy-item-inner">
      <texts
        v-if="!noTitle"
        class="trained-ai-accuracy-item-text"
        :text="name"
        :size="!isMinTitle ? 'large' : 'small'"
        :color="!isMinTitle ? 'emphasis' : 'gray'"
      />
      <metrics-text
        class="trained-ai-accuracy-item-text-main"
        :percentage="percentage"
        :value="value"
        :size="40"
        isBold
      />
    </div>
    <div v-if="percentage && !noTitle" class="trained-ai-accuracy-item-graph">
      <accuracyGraph :values="value * 100" type="pie" />
    </div>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import accuracyGraph from '@/components/atoms/accuracy-graph'
import metricsText from '@/components/atoms/metrics-text'

export default {
  name: 'Metrics',
  components: {
    texts,
    accuracyGraph,
    metricsText
  },
  props: {
    /** 表示する精度の名前 */
    name: String,
    /** 表示する精度の値 */
    value: Number,
    /** ％表示するか */
    percentage: Boolean,
    /** card-baseの中に入れるか */
    isCard: Boolean,
    /** タイトルを表示しない */
    noTitle: {
      type: Boolean,
      default: false
    },
    /** タイトルを最小化表示 */
    isMinTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.trained-ai-accuracy-item {
  display: flex;
  align-items: center;
  justify-content: center;
  &-inner {
    text-align: center;
  }
  &-graph {
    width: adjustVW(80);
    margin: 0 0 0 adjustVW(28);
  }
  &-text {
    margin-bottom: $space-sub;
    line-height: 1.2;
    &-main {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &-card {
    display: flex;
    flex-direction: row-reverse;
    .trained-ai-accuracy-item {
      &-inner {
        display: flex;
        flex-direction: column-reverse;
        text-align: left;
      }
      &-graph {
        margin: 0 $space-small 0 0;
      }
      &-text {
        margin-bottom: 0;
        &-main {
          margin-bottom: $space-sub;
        }
      }
    }
  }
  &-min-title {
    justify-content: flex-start;
    .trained-ai-accuracy-item {
      &-inner {
        display: flex;
        flex-direction: column-reverse;
        grid-row-gap: $space-sub;
        text-align: left;
      }
      &-graph {
        display: none;
      }
      &-text {
        margin-bottom: 0;
      }
    }
  }
}
</style>
