<template>
  <div>
    <texts
      class="title"
      :text="values.name"
      color="gray"
      size="small"
    />
    <div class="input">
      <MoleculesInputBox
        :value="values.params.value"
        required
        isGray
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  components: {
    MoleculesInputBox
  },
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateValue(value) {
      const res = JSON.parse(JSON.stringify(this.values))
      res.params.value = value
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-text;
}
</style>
