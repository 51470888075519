<template>
  <div class="version-wrap">
    <div class="version-title">
      <texts :text="$t('customblock.versionHistory')" size="large" />
    </div>
    <div class="version-list">
      <button
        v-for="item in history"
        :key="item.version"
        class="version-card"
        :class="{
          'version-card-active': item.version === detail.version
        }"
        @click="click(item)"
      >
        <div class="version-card-title">
          <texts size="large" :text="item.version" />
          <!--
            TODO バージョンの作成日が取得されたら実装
            <texts size="small" color="gray" :text="item"/>
          -->
        </div>
        <div v-if="item.release_note" class="version-card-description">
          <description :text="item.release_note" isGray />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description'

export default {
  components: {
    description
  },
  methods: {
    click(item) {
      if (item.version === this.detail.version) return
      this.$emit('version-click', item.version)
    }
  },
  props: {
    detail: Object,
    history: Array,
    loading: Boolean,
    versionState: Object
  }
}
</script>

<style lang="scss" scoped>
.version {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small;
    border-radius: adjustVW(16) 0 0 adjustVW(16);
  }
  &-title {
    margin-bottom: $space-sub;
  }
  &-list {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-card {
    width: 100%;
    padding: $space-base $space-sub;
    background-color: $background-sub;
    text-align: left;
    border-radius: adjustVW(8);
    cursor: pointer;
    &-title {
      display: flex;
      justify-content: space-between;
    }
    &-active {
      background-color: $key-lite;
      &:hover {
        opacity: 1;
      }
    }
    &-description {
      margin-bottom: $space-text;
    }
  }
}
</style>
