<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disabledClick || checkNoClose"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #save>
      <popup-save
        :detail="customBlockDetail"
        :newDetail="newDetail"
        :disabledClick="disabledClick"
        @save-customblock="$emit('save-customblock', $event)"
      />
    </template>
    <template #saveNew>
      <popup-save-new
        :detail="customBlockDetail"
        :newDetail="newDetail"
        :validatorState="validatorState"
        :disabledClick="disabledClick"
        @validation-change="$emit('validation-change', $event)"
        @save-customblock="$emit('save-customblock', $event)"
      />
    </template>
    <template #selectType>
      <popup-select-type
        @select-type="$emit('select-type', $event)"
        @cancel="$emit('close-modal', 'selectType')"
      />
    </template>
    <template #notYours>
      <popup-not-yours @cancel="$emit('close-modal', 'selectType')" />
    </template>
    <template #freePlan>
      <popup-free-plan />
    </template>
  </popup-array>
</template>

<script>
import PopupSave from './popup/save.vue'
import PopupSaveNew from './popup/save-new.vue'
import PopupSelectType from './popup/select-type.vue'
import PopupNotYours from './popup/not-yours.vue'
import PopupFreePlan from '@/components/organisms/customblock-list/popup/free-plan.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    PopupArray,
    PopupSave,
    PopupSaveNew,
    PopupNotYours,
    PopupSelectType,
    PopupFreePlan
  },
  props: {
    customBlockDetail: Object,
    newDetail: Object,
    validatorState: Object,

    showPopup: Array,
    disabledClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        save: {
          title: this.$t('customblock.popup.save.title'),
          maxWidth: true,
          maxHeight: true
        },
        saveNew: {
          title: this.$t('customblock.popup.saveNew.title'),
          maxWidth: true,
          maxHeight: true
        },
        selectType: {
          title: this.$t('customblock.popup.selectType.title')
        },
        notYours: {
          title: this.$t('customblock.popup.notYours.title'),
          noClose: true
        },
        freePlan: {
          title: this.$t('customblock.popup.freePlan.title'),
          noClose: true
        }
      }
    },
    checkNoClose() {
      if (this.popupDefs[this.showPopup] == null) return false
      return this.popupDefs[this.showPopup]?.noClose
    }
  }
}
</script>
