<template>
  <div class="metrics-multi">
    <div
      v-for="metric in limitMetrcis"
      :key="metric.columnName"
      class="metrics-multi-inner"
    >
      <texts
        class="metrics-multi-column"
        :text="metric.columnName"
        size="small"
      />
      <metrics-text
        class="metrics-multi-value"
        :value="metric.value"
        :percentage="metric.percentage"
        color="emphasis"
      />
    </div>
  </div>
</template>

<script>
import metricsText from '@/components/atoms/metrics-text'

export default {
  components: {
    metricsText
  },
  props: {
    /** [{ columnName: カラム名, name: 精度の名前, percentage: パーセント表示か否か, value: 精度の値 }] */
    metricsList: Array
  },
  computed: {
    limitMetrcis() {
      if (!this.metricsList || this.metricsList.length === 0) return []
      return this.metricsList.slice(0, 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.metrics-multi {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-base;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: $space-small;
    width: 100%;
    > .metrics-multi-list {
      margin-bottom: 0;
    }
  }
  &-value {
    flex-shrink: 0;
    margin-right: $space-base;
  }
}
</style>
