<template>
  <div class="c-wrap">
    <sidebar-customblock />
    <div class="c-body-wrap">
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="waitPageLoading" key="loading" />
          <page-not-found v-else-if="notFound" key="notFound" />
          <customblockDetail
            v-else
            :detail="detail"
            :loading="loading"
            :history="history"
            :historyLoading="historyLoading"
            :writable="writable"
            :validatorState="validatorState"
            @version-click="$emit('version-click', $event)"
            @input-edit-form="$emit('input-edit-form', $event)"
            @save-customblock="$emit('save-customblock', $event)"
            @menu-click="$emit('menu-click', $event)"
            @edit-customblock="$emit('edit-customblock', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="waitProcessLoading" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="dataset-list-popup">
      <Popup
        v-bind="popup"
        :customBlockDetail="detail"
        :disabledClick="waitProcessLoading"
        @close-modal="$emit('close-modal', $event)"
        @delete-customblock="$emit('delete-customblock', $event)"
        @goto-recipe="$emit('goto-recipe', $event)"
      />
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import SidebarCustomblock from '@/components/organisms/sidebar-customblock.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import customblockDetail from '@/components/organisms/customblock-detail/main.vue'
import PageNotFound from '@/components/organisms/page-not-found'
import Popup from '@/components/organisms/customblock-detail/popup'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    SidebarCustomblock,
    hoverMenu,
    loadingProcessing,
    customblockDetail,
    PageNotFound,
    Popup,
    transitionPage,
    TransitionToggleContents
  },
  props: {
    detail: Object,
    history: Array,

    loading: Boolean,
    notFound: Boolean,
    historyLoading: Boolean,
    writable: Boolean,
    validatorState: Object,

    popup: Object,

    waitPageLoading: Boolean,
    waitProcessLoading: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
