<template>
  <div>
    <div class="upload-requirements-title">
      <div class="upload-requirements-title-inner">
        <texts
          :text="$t('customblock.uploadRequirements')"
          size="small"
          color="gray"
        />
        <icons
          v-tooltip.auto="toolTipUploadRequirements"
          class="upload-requirements-icon"
          iconName="info"
          size="small"
          color="gray"
        />
      </div>
      <button @click="cancelUpload">
        <text-with-icon
          v-if="inputFileName"
          iconName="delete"
          size="small"
          :text="$t('customblock.button.deleteInput')"
          color="caution"
        />
      </button>
    </div>
    <div class="upload-requirements-input">
      <label
        class="upload-requirements-input-inner"
        :class="{
          'upload-requirements-input-inner-error': requirementsError
        }"
      >
        <input
          ref="uploadRequirements"
          class="upload-requirements-input-hidden"
          type="file"
          accept="text/*"
          @change="inputFile($event.target.files[0])"
        >
        <transition-toggle-contents>
          <texts
            v-if="inputFileName"
            key="file"
            :text="inputFileName"
            size="small"
            :color="requirementsError ? 'caution' : 'default'"
          />
          <texts
            v-else
            key="placeholder"
            :text="$t('common.button.selectFile')"
            size="small"
            color="gray"
          />
        </transition-toggle-contents>
      </label>
    </div>
    <transition-toggle-contents>
      <div v-if="requirementsError" class="upload-error">
        <text-box :text="requirementsError" isError />
      </div>
    </transition-toggle-contents>
    <div class="upload-example">
      <texts
        class="upload-example-title"
        :text="$t('customblock.popup.save.example')"
        size="min"
        color="gray"
      />
      <div class="upload-example-list">
        <texts text="numpy == 1.22.4" size="min" color="gray" />
        <texts
          text="git+https://github.com/numpy/numpy.git@v1.22.4"
          size="min"
          color="gray"
        />
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import textBox from '@/components/atoms/text-box.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import parseRequirements from './parseRequirements'

export default {
  mixins: [parseRequirements],
  components: {
    icons,
    textBox,
    textWithIcon,
    transitionToggleContents
  },
  data() {
    return {
      requirements: this.value,
      requirementsError: null,
      inputFileName: null
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    toolTipUploadRequirements() {
      return {
        content: this.$t('customblock.tooltip.uploadRequirements'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    }
  },
  methods: {
    cancelUpload() {
      this.inputFileName = null
      this.requirementsError = null
      this.$refs.uploadRequirements.value = ''
      this.requirements = null
      this.$emit('input', null)
    }
  },
  watch: {
    requirementsError(txt) {
      if (txt != null) {
        this.$emit('set-error', true)
      } else {
        this.$emit('set-error', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-requirements {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-sub;
    &-inner {
      display: flex;
      align-items: center;
      grid-column-gap: $space-small;
    }
  }
  &-icon {
    cursor: help;
  }
  &-input {
    display: flex;
    grid-column-gap: $space-small;
    margin-bottom: $space-small;
    &-inner {
      display: block;
      appearance: none;
      width: 100%;
      height: 100%;
      min-height: adjustVH(48);
      padding: $space-sub;
      background: $background-sub;
      border-radius: adjustVW(8);
      transition: box-shadow $transition-base, background-color $transition-base;
      &-error {
        background-color: $red-lite;
        box-shadow: $border-radius-caution inset;
      }
    }
    &-hidden {
      display: none;
    }
  }
}
.upload-example {
  padding: $space-sub;
  background-color: $background-sub;
  border-radius: adjustVW(8);
  &-title {
    margin-bottom: $space-text;
  }
  &-list {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-text;
  }
}
.upload-error {
  margin-bottom: $space-small;
}
</style>
