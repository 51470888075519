<template>
  <div class="save-wrap">
    <texts
      class="description"
      size="small"
      color="gray"
      isShowAll
      :text="$t('customblock.popup.selectType.description')"
    />
    <div class="save-item">
      <texts
        class="save-item-title"
        :text="$t('customblock.blockType')"
        size="small"
        color="gray"
      />
      <select-box
        class="select-box"
        isGray
        :items="typeOptions"
        :value="selectedType"
        @input="selected"
      />
    </div>
    <div class="save-item">
      <button-main
        type="emphasis"
        :text="$t('customblock.button.createCustomblock')"
        @click="saveCustomBlock"
      />
      <button-main
        type="sub"
        :text="$t('common.cancel')"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import selectBox from '@/components/molecules/select-box'
import texts from '@/components/atoms/text'

export default {
  name: 'SaveCustomBlockPopup',
  components: {
    texts,
    buttonMain,
    selectBox
  },
  data() {
    return {
      selectedType: 'classification'
    }
  },
  props: {},
  computed: {
    typeOptions() {
      return [
        {
          value: 'classification',
          text: this.$t('inference.type.classification')
        },
        {
          value: 'regression',
          text: this.$t('inference.type.regression')
        },
        {
          value: 'convert_dataset',
          text: this.$t('customblock.type.convert_dataset')
        }
        /*
        {
          value: 'acquire_dataset',
          text: this.$t('customblock.type.acquire_dataset')
        }
        */
      ]
    }
  },
  methods: {
    selected(v) {
      this.selectedType = v
    },
    saveCustomBlock() {
      this.$emit('select-type', this.selectedType)
    }
  }
}
</script>

<style lang="scss" scoped>
.save {
  &-wrap {
    display: flex;
    flex-direction: column;
  }
  &-item {
    &:not(:last-of-type) {
      margin: 0 0 $space-medium;
    }
    &-title {
      margin-bottom: $space-text;
    }
  }
}

.description {
  margin-bottom: $space-small;
  white-space: pre-line;
}

.select-box {
  width: adjustVW(280);
}
</style>
