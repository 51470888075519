<template>
  <div class="param-panel">
    <div
      v-if="requirements && Object.keys(requirements).length > 0"
      class="section"
    >
      <div class="title">
        <texts :text="$t('customblock.addedPackages')" size="large" />
      </div>
      <div class="reqs-list">
        <requirements-item
          v-for="(item, name) in requirements"
          :key="name"
          :name="name"
          :version="item"
          :size="18"
        />
      </div>
    </div>
    <div class="section">
      <div class="title">
        <texts :text="$t('customblock.defaultPackages')" size="large" />
      </div>
      <div class="reqs-list">
        <requirements-item
          v-for="(item, name) in defaultPackages"
          :key="name"
          :name="name"
          :version="item"
          :size="18"
        />
      </div>
    </div>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import { DEFAULT_PACKAGES } from '@/lib/customblockDefaultLibraries'
import requirementsItem from './requirements-item'

export default {
  components: {
    texts,
    requirementsItem
  },
  computed: {
    defaultPackages() {
      return DEFAULT_PACKAGES
    }
  },
  props: {
    requirements: Object
  }
}
</script>

<style lang="scss" scoped>
.param-panel {
  overflow-y: scroll;
  height: 100%;
  padding-right: $space-small;
  @include scrollbar;
}
.section {
  &:not(:last-of-type) {
    padding-bottom: $space-small;
    margin-bottom: $space-small;
    border-bottom: $border-sub;
  }
}
.title {
  margin-bottom: $space-base;
}
.reqs-list {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
}
</style>
