<template>
  <div class="c-wrap">
    <sidebar-customblock />
    <div class="c-body-wrap">
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="waitPageLoading" />
          <customblockList
            v-else
            :list="customblockList"
            :accountInfo="accountInfo"
            @menu-click="$emit('menu-click', $event)"
            @show-customblock="$emit('show-customblock', $event)"
            @new-customblock="$emit('new-customblock')"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="deleteLoading" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="dataset-list-popup">
      <Popup
        v-bind="popup"
        :disabledClick="deleteLoading"
        @close-modal="$emit('close-modal', $event)"
        @delete-customblock="$emit('delete-customblock', $event)"
        @goto-recipe="$emit('goto-recipe', $event)"
      />
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import SidebarCustomblock from '@/components/organisms/sidebar-customblock.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import customblockList from '@/components/organisms/customblock-list/main.vue'
import Popup from '@/components/organisms/customblock-list/popup'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    SidebarCustomblock,
    hoverMenu,
    loadingProcessing,
    customblockList,
    Popup,
    transitionPage,
    TransitionToggleContents
  },
  props: {
    customblockList: Array,
    deleteLoading: Boolean,
    popup: Object,
    accountInfo: Object,
    waitPageLoading: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
