<template>
  <div
    class="params-wrap"
    :class="{
      'params-wrap-edit': edit
    }"
  >
    <params-edit
      v-if="edit"
      class="params-edit"
      :isNew="isNew"
      :editVariableName="valiableName"
      :value="value"
      :validateOk="validateOk"
      :validateValiableName="validateValiableName"
      @cancel-edit="cancelEdit"
      @save-params="updateParam"
      @validate-valiable-name="validatingName = $event"
    />
    <div v-else class="params-list">
      <div class="params-list-reqs">
        <requirements-list
          :requirements="requirements"
          @add="clickAdd"
          @edit="clickEdit"
          @remove="clickRemove"
        />
      </div>
      <div class="params-list-params">
        <params-list
          :params="params"
          @add="clickAdd"
          @edit="clickEdit"
          @remove="clickRemove"
        />
      </div>
    </div>
  </div>
</template>
<script>
import paramsList from './params/params-list'
import paramsEdit from '../edit-params/edit-params'
import requirementsList from './requirements/requirements-list'
export default {
  components: {
    paramsList,
    paramsEdit,
    requirementsList
  },
  data() {
    return {
      valiableName: '',
      validatingName: '',
      edit: false,
      isNew: false
    }
  },
  methods: {
    clickAdd() {
      this.valiableName = null
      this.isNew = true
      this.edit = true
    },
    clickEdit(valiableName) {
      this.valiableName = valiableName
      this.isNew = false
      this.edit = true
    },
    cancelEdit() {
      this.edit = false
    },
    clickRemove(e) {
      this.$emit('remove-param', e)
    },
    updateParam(e) {
      this.$emit('update-param', {
        name: this.valiableName,
        params: e
      })
      this.edit = false
    }
  },
  computed: {
    value() {
      if (this.valiableName == null) return null
      return this.params[this.valiableName]
    },
    validateOk() {
      return this.validateValiableName
    },
    validateValiableName() {
      if (this.validatingName === this.valiableName) return true
      if (this.params[this.validatingName] != null) return false
      return true
    }
  },
  props: {
    params: Object,
    requirements: Object
  }
}
</script>

<style lang="scss" scoped>
.params {
  &-wrap {
    width: adjustVW(304 * 2);
    background-color: $white;
    transition: $transition-base width;
    &-edit {
      width: adjustVW(912);
    }
  }
  &-edit {
    width: 100%;
  }
  &-list {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - #{$space-small / 2}));
    grid-column-gap: $space-small;
    height: 100%;
    padding: $space-small;
    &-reqs {
      overflow: hidden;
      height: 100%;
      padding-right: $space-small;
      border-right: $border-sub;
    }
    &-params {
      overflow: hidden;
      height: 100%;
    }
  }
}
</style>
