<!--
  画面上に大きく表示するリストデータセット一覧などに使用する
-->
<template>
  <div class="list-wrap">
    <div class="list-body" :class="{ 'list-body-checked': someChecked }">
      <div class="list-name-block">
        <div
          v-for="(title, index) in field"
          :key="title.id"
          class="list-name-inner"
          :class="'list-name-inner-' + title.thClass"
        >
          <div v-if="index === 0" class="list-name-checkbox">
            <checkbox-base
              :checked="nameCheck()"
              :isShowMinusIcon="isNotAllChecked"
              @input="setCheckAll($event)"
            />
          </div>
          <div v-if="!title.sortable" class="list-name-item">
            <texts :text="title.label" />
          </div>
          <button v-else class="list-name-item" @click="clickSort(title)">
            <texts
              :text="title.label"
              :color="sortedBy === title.key ? 'emphasis' : 'default'"
            />
            <div
              class="list-name-item-icon"
              :class="{ active: sortedBy === title.key }"
            >
              <icons
                iconName="toggle"
                size="large"
                :class="{ inverted: sortOrder === 'asc' }"
                :color="sortedBy === title.key ? 'emphasis' : 'gray'"
              />
            </div>
          </button>
        </div>
        <div class="list-menu-icon">
          <!-- <button class="list-menu-icon-item"
            v-for="iconMenu in iconMenus"
            :key='iconMenu.id'
          >
            <icons :iconName='iconMenu.icon' size="small"/>
          </button> -->
          <div class="list-menu-icon-link">
            <icons :iconName="buttonIcon ? buttonIcon : 'forward'" />
          </div>
        </div>
      </div>

      <div v-if="isButton" class="list-data-wrap">
        <template v-if="sortedItems && sortedItems.length > 0">
          <button
            v-for="item in sortedItems"
            :key="item[idColumn]"
            class="list-data-button"
            @click="$emit('list-click', { ...item })"
          >
            <div class="list-data-button-inner">
              <div
                v-for="(key, columnIndex) in field"
                :key="key.id"
                class="list-data-inner"
                :class="[
                  'list-data-inner-' + key.thClass,
                  'list-data-inner-' + key.listType
                ]"
              >
                <!-- 表示するリストの要素の名前や説明文 -->
                <template v-if="key.listType === 'name'">
                  <div
                    v-if="columnIndex === 0"
                    class="list-data-checkbox"
                    @click.stop
                  >
                    <checkbox-base
                      :checked="isChecked(item[idColumn])"
                      @input="setCheck($event, item[idColumn])"
                    />
                  </div>
                  <div class="list-data-item">
                    <div class="list-data-item-title">
                      <text-with-icon
                        :text="item.name"
                        :iconName="isNameIcon ? item.type : null"
                        isBold
                      />
                    </div>
                    <description :text="item.description" line="2" />
                  </div>
                </template>

                <!-- 表示するリストの要素がリンクの場合 -->
                <template v-else-if="key.listType === 'link'">
                  <div
                    v-if="columnIndex === 0"
                    class="list-data-checkbox"
                    @click.stop
                  >
                    <checkbox-base
                      :checked="isChecked(item[idColumn])"
                      @input="setCheck($event, item[idColumn])"
                    />
                  </div>
                  <div class="list-data-item">
                    <router-link
                      class="list-data-item list-data-inner-link"
                      :to="fixKey(item, key.key).link"
                    >
                      <texts :text="fixKey(item, key.key).name" color="link" />
                    </router-link>
                  </div>
                </template>

                <!-- 表示するリストの要素がリンクの場合 -->
                <template v-else-if="key.listType === 'time'">
                  <div class="list-data-item">
                    <texts>
                      <a-time
                        :value="fixKey(item, key.key)"
                        :withTime="key.withTime"
                      />
                    </texts>
                  </div>
                </template>

                <!-- リスト形の場合 -->
                <template v-else-if="key.listType === 'list'">
                  <div
                    v-if="columnIndex === 0"
                    class="list-data-checkbox"
                    @click.stop
                  >
                    <checkbox-base
                      :checked="isChecked(item[idColumn])"
                      @input="setCheck($event, item[idColumn])"
                    />
                  </div>
                  <template
                    v-for="(list, index) in clipList(fixKey(item, key.key))"
                  >
                    <div :key="index" class="list-data-list">
                      <div class="list-data-list-inner">
                        {{ list.name }}
                      </div>
                    </div>
                  </template>
                </template>

                <!-- 重要度の場合(リスト系だがObjectで渡されるため) -->
                <template v-else-if="key.listType === 'featureImportance'">
                  <div
                    v-if="columnIndex === 0"
                    class="list-data-checkbox"
                    @click.stop
                  >
                    <checkbox-base
                      :checked="isChecked(item[idColumn])"
                      @input="setCheck($event, item[idColumn])"
                    />
                  </div>
                  <list-features
                    v-if="
                      fixKey(item, key.key) &&
                        Object.keys(fixKey(item, key.key)).length > 0
                    "
                    :id="item.id"
                    :predictionColumns="item.predictColumns"
                    :featureImportance="fixKey(item, key.key)"
                  />
                </template>

                <!-- 精度の場合 -->
                <template v-else-if="key.listType === 'accuracy'">
                  <div v-if="columnIndex === 0" class="list-data-checkbox">
                    <checkbox-base />
                  </div>
                  <div class="list-data-item">
                    <template
                      v-if="
                        item['metrics'] &&
                          Object.keys(item['metrics']).length > 0
                      "
                    >
                      <metrics-multi
                        v-if="Object.keys(item['metrics']).length > 1"
                        :metricsList="item.metricsList"
                      />
                      <template v-else>
                        <metrics
                          v-for="metric in item.metricsList"
                          :key="metric.name + key"
                          :name="metric.name"
                          :value="metric.value"
                          :percentage="metric.percentage"
                          isMinTitle
                        />
                      </template>
                    </template>
                    <div v-else>
                      <texts
                        class="list-data-item-accuracy-none"
                        :text="$t('common.noMetrics')"
                        size="min"
                        color="gray"
                      />
                    </div>
                  </div>
                </template>

                <!-- 前処理内容の場合 -->
                <template v-else-if="key.listType === 'preprocessing'">
                  <div v-if="columnIndex === 0" class="list-data-checkbox">
                    <checkbox-base />
                  </div>
                  <div>
                    <preprocessing-chart-list-min
                      class="list-data-item-preprocessing"
                      :layers="fixKey(item, key.key)"
                    />
                  </div>
                </template>

                <!-- データサイズの場合 -->
                <template v-else-if="key.listType === 'dataSize'">
                  <div v-if="columnIndex === 0" class="list-data-checkbox">
                    <checkbox-base />
                  </div>
                  <div class="list-data-item">
                    <texts :text="formatFileSize(fixKey(item, key.key))" />
                  </div>
                </template>

                <template v-else-if="key.listType === 'optimization'">
                  <div v-if="columnIndex === 0" class="list-data-checkbox">
                    <checkbox-base />
                  </div>
                  <div class="list-data-item">
                    <optimization-conditions-list
                      :optimizationConditions="
                        item.latestOptimizationConditions
                      "
                      :limit="3"
                    />
                  </div>
                </template>

                <!-- 通常の場合 -->
                <template v-else>
                  <div
                    v-if="columnIndex === 0"
                    class="list-data-checkbox"
                    @click.stop
                  >
                    <checkbox-base
                      :checked="isChecked(item[idColumn])"
                      @input="setCheck($event, item[idColumn])"
                    />
                  </div>
                  <div class="list-data-item">
                    <texts :text="fixKey(item, key.key)" />
                  </div>
                </template>
              </div>
              <!-- リストに表示されるアイコンメニュー -->
              <div class="list-menu-icon">
                <div class="list-menu-icon-link">
                  <!-- リンクではなくポップアップが開くアイコンが入ります -->
                  <icons :iconName="buttonIcon ? buttonIcon : 'forward'" />
                </div>
              </div>
            </div>
          </button>
        </template>
        <div v-else-if="noSearchResults" class="list-no-item">
          <images
            class="list-no-item-image list-no-item-image-search"
            imageName="notFound"
          />
          <texts
            class="list-no-item-text"
            :text="$t('common.noSearchResults')"
          />
        </div>
        <div v-else class="list-no-item">
          <images class="list-no-item-image" imageName="noItem" />
          <texts class="list-no-item-text" :text="$t('common.listNoItem')" />
        </div>
      </div>
      <div v-else class="list-data-wrap">
        <template v-if="sortedItems && sortedItems.length > 0">
          <router-link
            v-for="item in sortedItems"
            :key="item[idColumn]"
            class="list-data-block"
            :to="
              item.link
                ? { name: item.link.name, params: { id: item.link.params } }
                : {}
            "
            :class="{ 'list-data-block-checked': isChecked(item[idColumn]) }"
          >
            <div
              v-for="(key, columnIndex) in field"
              :key="key.id"
              class="list-data-inner"
              :class="[
                'list-data-inner-' + key.thClass,
                'list-data-inner-' + key.listType
              ]"
            >
              <!-- 表示するリストの要素の名前や説明文 -->
              <template v-if="key.listType === 'name'">
                <div
                  v-if="columnIndex === 0"
                  class="list-data-checkbox"
                  @click.stop
                >
                  <checkbox-base
                    :checked="isChecked(item[idColumn])"
                    @input="setCheck($event, item[idColumn])"
                  />
                </div>
                <div class="list-data-item">
                  <div class="list-data-item-title">
                    <text-with-icon
                      :text="item.name"
                      :iconName="isNameIcon ? item.type : null"
                      isBold
                    />
                  </div>
                  <description :text="item.description" line="2" />
                </div>
              </template>

              <!-- 表示するリストの要素がリンクの場合 -->
              <template v-else-if="key.listType === 'link'">
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div class="list-data-item">
                  <router-link
                    class="list-data-item list-data-inner-link"
                    :to="fixKey(item, key.key).link"
                  >
                    <texts :text="fixKey(item, key.key).name" color="link" />
                  </router-link>
                </div>
              </template>

              <!-- 表示するリストの要素がリンクの場合 -->
              <template v-else-if="key.listType === 'time'">
                <div class="list-data-item">
                  <texts>
                    <a-time :value="fixKey(item, key.key)" />
                  </texts>
                </div>
              </template>

              <!-- リスト形の場合 -->
              <template v-else-if="key.listType === 'list'">
                <div
                  v-if="columnIndex === 0"
                  class="list-data-checkbox"
                  @click.stop
                >
                  <checkbox-base
                    :checked="isChecked(item[idColumn])"
                    @input="setCheck($event, item[idColumn])"
                  />
                </div>
                <template
                  v-for="(list, index) in clipList(fixKey(item, key.key))"
                >
                  <div :key="index" class="list-data-list">
                    <div class="list-data-list-inner">
                      {{ list.name }}
                    </div>
                  </div>
                </template>
              </template>

              <!-- 重要度の場合(リスト系だがObjectで渡されるため) -->
              <template v-else-if="key.listType === 'featureImportance'">
                <div
                  v-if="columnIndex === 0"
                  class="list-data-checkbox"
                  @click.stop
                >
                  <checkbox-base
                    :checked="isChecked(item[idColumn])"
                    @input="setCheck($event, item[idColumn])"
                  />
                </div>
                <list-features
                  :id="item.id"
                  :predictionColumns="item.predictionColumns"
                  :featureImportance="fixKey(item, key.key)"
                />
              </template>

              <!-- 精度の場合 -->
              <template v-else-if="key.listType === 'accuracy'">
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div class="list-data-item">
                  <div v-if="item['type'] === 'NO_ACCURACY'">
                    <texts
                      class="list-data-item-accuracy-none"
                      :text="$t('trainedAiDetails.noAccuracy')"
                      size="min"
                      color="gray"
                    />
                  </div>
                  <template
                    v-else-if="
                      item['metrics'] && Object.keys(item['metrics']).length > 0
                    "
                  >
                    <metrics-multi
                      v-if="Object.keys(item['metrics']).length > 1"
                      :metricsList="item.metricsList"
                    />
                    <template v-else>
                      <metrics
                        v-for="metric in item.metricsList"
                        :key="metric.name + key"
                        :name="metric.name"
                        :value="metric.value"
                        :percentage="metric.percentage"
                        isMinTitle
                      />
                    </template>
                  </template>
                  <div v-else>
                    <texts
                      class="list-data-item-accuracy-none"
                      :text="$t('common.noMetrics')"
                      size="min"
                      color="gray"
                    />
                  </div>
                </div>
              </template>

              <!-- 前処理内容の場合 -->
              <template v-else-if="key.listType === 'preprocessing'">
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div>
                  <preprocessing-chart-list-min
                    class="list-data-item-preprocessing"
                    :layers="fixKey(item, key.key)"
                  />
                </div>
              </template>

              <!-- データサイズの場合 -->
              <template v-else-if="key.listType === 'dataSize'">
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div class="list-data-item">
                  <texts :text="formatFileSize(fixKey(item, key.key))" />
                </div>
              </template>

              <template v-else-if="key.listType === 'optimization'">
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div class="list-data-item">
                  <optimization-conditions-list
                    :optimizationConditions="item.latestOptimizationConditions"
                    :limit="3"
                  />
                </div>
              </template>

              <!-- 通常の場合 -->
              <template v-else>
                <div v-if="columnIndex === 0" class="list-data-checkbox">
                  <checkbox-base />
                </div>
                <div class="list-data-item">
                  <texts :text="fixKey(item, key.key)" />
                </div>
              </template>
            </div>
            <!-- リストに表示されるアイコンメニュー -->
            <div class="list-menu-icon">
              <div class="list-menu-icon-link">
                <icons iconName="forward" />
              </div>
            </div>
          </router-link>
        </template>
        <div v-else-if="noSearchResults" class="list-no-item">
          <images
            class="list-no-item-image list-no-item-image-search"
            imageName="notFound"
          />
          <texts
            class="list-no-item-text"
            :text="$t('common.noSearchResults')"
          />
        </div>
        <div v-else class="list-no-item">
          <images class="list-no-item-image" imageName="noItem" />
          <texts class="list-no-item-text" :text="$t('common.listNoItem')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import images from '@/components/atoms/images'
import description from '@/components/atoms/description'
import checkboxBase from '@/components/atoms/checkbox-base'
import textWithIcon from '@/components/molecules/text-with-icon'
import preprocessingChartListMin from '@/components/molecules/preprocessing-chart-list-min.vue'
import Metrics from '@/components/molecules/graph/metrics.vue'
import MetricsMulti from '@/components/molecules/graph/metrics-multi.vue'
import listFeatures from '@/components/organisms/trained-ai-common/list-features.vue'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

export default {
  components: {
    texts,
    icons,
    images,
    description,
    checkboxBase,
    textWithIcon,
    preprocessingChartListMin,
    Metrics,
    MetricsMulti,
    listFeatures,
    optimizationConditionsList
  },
  data() {
    return {
      targetIcon: '', // 現在ホバーしているアイコン
      checked: new Set(this.value),
      sortedBy: this.defaultSortedBy ?? null,
      sortOrder: 'desc',
      isNotAllChecked: false
    }
  },
  props: {
    /** リストのラベル */
    field: Array,
    /** 表示するリスト本体 */
    items: Array,
    /** リストをボタンとして扱う場合 */
    isButton: {
      type: Boolean,
      default: false
    },
    /** v-modelで渡すチェックボックスの値 */
    value: Array,
    /** keyとして使う値をidから変更する場合に使用 */
    idColumn: { type: String, default: 'id' },
    /** アイテムの名前の横にアイコンを表示する */
    isNameIcon: {
      type: Boolean,
      default: false
    },
    /** defaultSortedByで指定したソートを使用しない */
    manualSort: {
      type: Boolean,
      default: false
    },
    /** 検索結果が見つからなかったときに画像を表示 */
    noSearchResults: {
      type: Boolean,
      default: false
    },
    /** デフォルトのソート条件 desc or asc */
    defaultSortedBy: {
      type: String
    },
    /** ホバーしたときに表示されるアイコン デフォルトはforward */
    buttonIcon: String
  },
  computed: {
    someChecked() {
      return this.checked.size > 0
    },
    sortedItems() {
      if (this.sortedBy == null || this.manualSort) return this.items
      const sortedBy = this.sortedBy
      if (this.sortOrder === 'asc') {
        return [...this.items].sort((x, y) =>
          x[sortedBy] < y[sortedBy] ? -1 : x[sortedBy] > y[sortedBy] ? 1 : 0
        )
      } else {
        return [...this.items].sort((x, y) =>
          x[sortedBy] < y[sortedBy] ? 1 : x[sortedBy] > y[sortedBy] ? -1 : 0
        )
      }
    }
  },
  methods: {
    isChecked(id) {
      return this.checked.has(id)
    },
    resetCheck() {
      this.checked = new Set()
      this.$emit('input', Array.from(this.checked))
    },
    setCheck(e, id) {
      if (e) {
        this.checked.add(id)
      } else {
        this.checked.delete(id)
      }
      this.checked = new Set(this.checked)
      this.$emit('input', Array.from(this.checked))
    },
    setCheckAll(e) {
      if (e) {
        for (const item of this.items) {
          this.checked.add(item[this.idColumn])
        }
        this.checked = new Set(this.checked)
      } else {
        this.checked = new Set()
      }
      this.$emit('input', Array.from(this.checked))
    },
    clipList(e) {
      // リスト表示は最大３つ
      return e.slice(0, 3)
    },
    clickSort(field) {
      if (this.sortedBy === field.key) {
        this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc'
      } else {
        this.sortOrder = field.sortOrder ?? 'asc'
      }
      this.$emit('sort', { sortBy: field.key, sortOrder: this.sortOrder })
      this.sortedBy = field.key
    },
    nameCheck() {
      if (this.someChecked) {
        if (this.checked.size === this.items.length) {
          this.isNotAllChecked = false
          return true
        } else {
          this.isNotAllChecked = true
          return true
        }
      } else {
        this.isNotAllChecked = true
        return false
      }
    },
    fixKey(item, key) {
      if (typeof key === 'object') {
        // key の Arrayで最初にヒットしたものを返す
        const targetKey = key.find((target) => {
          return item[target] != null
        })
        return item[targetKey]
      } else {
        return item[key]
      }
    }
  },
  watch: {
    value(newVal) {
      this.checked = new Set(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.inverted {
  transform: scaleY(-1);
}

.list {
  &-wrap {
    overflow: hidden;
    height: 100%;
    padding: $space-small;
    background: #fff;
    border-radius: adjustVW(16);
  }
  &-body {
    width: 100%;
    height: 100%;
    margin: 0 0 $space-large;
  }
  &-name {
    &-block {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      height: adjustVH(64);
      padding: 0 $space-medium;
      margin: 0 $space-sub + $space-base 0 0;
      background: $background;
    }
    &-inner {
      overflow: hidden;
      flex-grow: 1;
      width: adjustVW(120);
      padding: 0 $space-medium 0 0;
      &-name {
        flex-grow: 4;
        width: 100%;
        max-width: adjustVW(480);
      }
      &-large {
        flex-grow: 2;
        min-width: adjustVW(240);
      }
      &-preprocessing {
        flex-grow: 5;
        min-width: adjustVW(608);
      }
    }
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: $space-small 0;
      padding-left: 0;
      text-align: left;
      transition: $transition-base;
      .list-name-item-center & {
        text-align: center;
      }
      &-icon {
        margin: 0 0 0 $space-base;
        opacity: 0;
        transition: $transition-base;
        &.active {
          color: $text-decoration;
          opacity: 1;
        }
        .list-name-item:hover & {
          opacity: 1;
        }
      }
      .list-name-block:hover .list-name-inner:first-child & {
        padding-left: adjustVW(40);
      }
      .list-body-checked .list-name-inner:first-child & {
        padding-left: adjustVW(40);
      }
    }
    &-checkbox {
      position: absolute;
      top: 50%;
      width: 0;
      margin-right: 0;
      opacity: 0;
      z-index: 10;
      transform: translateY(-50%);
      transition: all $transition-base;
      .list-name-block:hover & {
        width: adjustVW(24);
        margin-right: $space-small;
        opacity: 1;
      }
      .list-body-checked & {
        width: adjustVW(24);
        margin-right: $space-small;
        opacity: 1;
      }
    }
  }
  &-data {
    &-wrap {
      overflow-y: scroll;
      height: calc(100% - #{adjustVH(64)});
      padding: 0 $space-sub 0 0;
      @include scrollbar;
    }
    &-block {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 $space-medium;
      margin: 0 0 $space-small;
      border: 2px solid transparent;
      background: $background;
      border-radius: adjustVW(16);
      transition: $transition-base;
      &:hover {
        background: $key-sub;
        opacity: 1;
      }
      &:last-child {
        margin: 0;
      }
    }
    &-inner {
      overflow: hidden;
      flex-grow: 1;
      width: adjustVW(120);
      padding: 0 $space-medium 0 0;
      &-name {
        flex-grow: 4;
        width: 100%;
        max-width: adjustVW(480);
      }
      &-large {
        flex-grow: 2;
        min-width: adjustVW(240);
      }
      &-preprocessing {
        flex-grow: 5;
        min-width: adjustVW(608);
      }
    }
    &-item {
      display: block;
      width: 100%;
      padding: $space-small 0;
      padding-left: 0;
      text-align: left;
      transition: padding-left $transition-base;
      &-title {
        margin: 0 0 $space-base;
      }
      &-center {
        text-align: center;
      }
      .list-data-block:hover .list-data-inner:first-child &,
      .list-data-button:hover .list-data-inner:first-child & {
        padding-left: adjustVW(40);
      }
      .list-body-checked .list-data-inner:first-child & {
        padding-left: adjustVW(40);
      }
      &-preprocessing {
        overflow-y: auto;
        padding: 0 $space-sub 0 0;
        @include scrollbar;
      }
      &-accuracy-none {
        word-break: break-all;
        white-space: pre-line;
      }
    }
    &-button {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 0 $space-small;
      &:hover {
        opacity: 1;
      }
      &-inner {
        display: flex;
        align-items: center;
        padding: 0 $space-medium;
        background: $background;
        transition: $transition-base;
        &:hover {
          background: $key-sub;
          border-radius: adjustVW(16);
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &-checkbox {
      position: absolute;
      top: 50%;
      width: 0;
      margin-right: 0;
      opacity: 0;
      transform: translateY(-50%);
      transition: all $transition-base;
      .list-data-block:hover &,
      .list-data-button:hover & {
        width: adjustVW(24);
        margin-right: $space-small;
        opacity: 1;
      }
      .list-body-checked & {
        width: adjustVW(24);
        margin-right: $space-small;
        opacity: 1;
      }
    }
    &-list {
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 $space-base;
      &::before {
        content: '';
        display: block;
        width: adjustVW(12);
        height: adjustVW(12);
        margin: 0 $space-base 0 0;
        background: $key-color;
        border-radius: 9in;
      }
      &:last-child {
        margin: 0;
      }
      &-inner {
        overflow: hidden;
        width: calc(100% - #{adjustVW(20)});
        color: $text-main;
        font-size: $text-base;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include text-crop;
      }
    }
  }
  &-menu-icon {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: $transition-base;
    .list-data-block:hover &,
    .list-data-button:hover &,
    &.active {
      opacity: 1;
    }
    &-item {
      padding: $space-sub;
      margin: 0 $space-small 0 0;
      background: $background;
      border-radius: adjustVW(8);
      transition: $transition-base;
      &:hover {
        background: $key-lite;
      }
    }
    &-link {
      display: inline-block;
    }
  }
  &-no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &-image {
      width: adjustVW(480);
      height: auto;
      margin-bottom: $space-large;
      &-search {
        width: adjustVW(460);
      }
    }
    &-text {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
