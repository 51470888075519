<template>
  <div class="param-item">
    <div>
      <texts :text="item.name" :size="18" />
      <texts :text="valiableName" :size="18" color="gray" />
    </div>
    <transition-toggle-contents>
      <div v-if="!showDelete" class="param-button">
        <icons iconName="edit" isButton @icon-click="$emit('edit')" />
        <icons iconName="delete" isButton @icon-click="showDelete = true" />
      </div>
      <delete-params
        v-else
        :is-show="showDelete"
        @confirm-delete="$emit('remove')"
        @discard-delete="showDelete = false"
      />
    </transition-toggle-contents>
  </div>
</template>
<script>
import icons from '@/components/atoms/icon.vue'
import DeleteParams from '@/components/molecules/delete-params/delete-params'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    icons,
    DeleteParams,
    TransitionToggleContents
  },
  data() {
    return {
      showDelete: false
    }
  },
  props: {
    valiableName: String,
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.param-item {
  display: flex;
  justify-content: space-between;
}
.param-button {
  display: flex;
  grid-column-gap: $space-medium;
}
</style>
