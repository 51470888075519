<template>
  <div class="param-panel-wrap">
    <div class="param-panel-inner">
      <div
        v-if="requirements != null && Object.keys(requirements).length > 0"
        class="section"
      >
        <div class="title">
          <texts :text="$t('customblock.addedPackages')" size="min" isBold />
        </div>
        <div class="reqs-list">
          <requirements-item
            v-for="(item, name) in requirements"
            :key="name"
            class="reqs-item"
            :name="name"
            :version="item"
          />
        </div>
      </div>
      <div class="section">
        <div class="title">
          <texts :text="$t('customblock.defaultPackages')" size="min" isBold />
        </div>
        <div class="reqs-list">
          <requirements-item
            v-for="(item, name) in defaultPackages"
            :key="name"
            class="reqs-item"
            :name="name"
            :version="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import { DEFAULT_PACKAGES } from '@/lib/customblockDefaultLibraries'
import requirementsItem from '@/components/organisms/customblock-edit/right-side/requirements/requirements-item'

export default {
  components: {
    texts,
    requirementsItem
  },
  computed: {
    defaultPackages() {
      return DEFAULT_PACKAGES
    }
  },
  props: {
    requirements: Object
  }
}
</script>

<style lang="scss" scoped>
.param-panel {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-sub $space-base $space-sub $space-small;
  }
  &-inner {
    overflow-y: auto;
    padding-right: $space-base;
    @include scrollbar;
  }
}

.title {
  margin-bottom: $space-base;
}
.reqs {
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: $space-base;
  }
  &-item {
    overflow: hidden;
    &:not(:nth-of-type(3n)) {
      padding-right: $space-sub;
      margin-right: $space-sub;
      border-right: $border-sub;
    }
    &:last-of-type {
      border-right: inherit;
    }
  }
}

.section {
  &:not(:last-of-type) {
    margin-bottom: $space-small;
  }
}
</style>
