<template>
  <div class="save-wrap">
    <div class="save-main">
      <div class="save-item">
        <input-box-validation
          v-model="name"
          :accept="validatorState.duplicate"
          :error="name !== '' && !validatorState.duplicate"
          :title="$t('customblock.popup.saveNew.name')"
          popupView
          isNameValidation
          :nameValidationSuggests="validatorState.suggests"
          @select-suggest="selectSuggest"
          @input="validationChange"
        />
      </div>
      <div class="save-item">
        <input-box
          v-model="description"
          isGray
          isTextArea
          :title="$t('common.description')"
        />
      </div>
      <div class="save-item">
        <upload-requirements
          v-model="requirements"
          @set-error="setRequirementsError"
        />
      </div>
      <div class="foot">
        <button-large
          :isDisabled="disabledSubmit || disabledClick"
          :text="$t('customblock.popup.saveNew.title')"
          @click="saveCustomBlock"
        />
      </div>
    </div>
    <div class="save-detail">
      <div class="save-detail-inner">
        <codeBox class="codebox" :code="newDetail.code" readonly />
        <params-list class="params-list" :params="newDetail.params" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLarge from '@/components/atoms/button-large.vue'
import InputBox from '@/components/molecules/input-box'
import InputBoxValidation from '@/components/molecules/input-box-validation.vue'
import codeBox from '../code.vue'
import ParamsList from '../../customblock-detail/params/params-list'
import uploadRequirements from './upload-requirements.vue'

export default {
  name: 'SaveNewCustomBlockPopup',
  components: {
    ButtonLarge,
    InputBox,
    InputBoxValidation,
    codeBox,
    ParamsList,
    uploadRequirements
  },
  data() {
    return {
      name: '',
      description: '',
      requirements: null,
      requirementsError: null
    }
  },
  props: {
    detail: Object,
    newDetail: Object,
    validatorState: Object,
    disabledClick: Boolean
  },
  computed: {
    disabledSubmit: function () {
      if (this.name === '') return true
      if (!this.validatorState.duplicate) return true
      if (this.requirementsError === true) return true

      return false
    },
    toolTipUploadRequirements() {
      return {
        content: this.$t('customblock.tooltip.uploadRequirements'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    }
  },
  methods: {
    saveCustomBlock() {
      const detail = JSON.parse(JSON.stringify(this.newDetail))
      detail.name = this.name
      detail.description = this.description
      detail.requirements = this.requirements
      this.$emit('save-customblock', detail)
    },
    validationChange() {
      this.$emit('validation-change', {
        name: this.name
      })
    },
    setRequirementsError(bool) {
      this.requirementsError = bool
    }
  },
  async mounted() {
    this.$emit('validation-change', {
      name: this.name
    })
  }
}
</script>

<style lang="scss" scoped>
.save {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-error {
    color: $text-caution;
    font-size: $text-base;
  }
  &-upload {
    display: flex;
    font-size: $text-base;
  }
  &-item {
    margin: 0 0 $space-medium;
  }
  &-detail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      display: grid;
      grid-template-areas: 'codebox params';
      grid-template-columns: 1fr adjustVW(298);
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    .codebox {
      overflow: hidden;
      grid-area: codebox;
      border-right: $border-sub;
    }
    .params-list {
      overflow: hidden;
      grid-area: params;
    }
  }
}
</style>
