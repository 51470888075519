<template>
  <div class="version-select">
    <div class="version-select-title">
      <texts
        :text="$t('customblock.versionSelect')"
        size="small"
        color="gray"
      />
    </div>
    <div class="version-select-body">
      <radio-button-base
        :checked="value === candidates[0]"
        :text="candidates[0]"
        @input="updateValues($event)"
      />
      <radio-button-base
        :checked="value === candidates[1]"
        :text="candidates[1]"
        @input="updateValues($event)"
      />
      <radio-button-base
        :checked="value === candidates[2]"
        :text="candidates[2]"
        @input="updateValues($event)"
      />
    </div>
  </div>
</template>

<script>
import radioButtonBase from '@/components/atoms/radio-button-base'

function newVersionCandidates(oldVer) {
  const candidates = [oldVer.split('.'), oldVer.split('.'), oldVer.split('.')]
  candidates[0][2] = parseInt(candidates[0][2]) + 1
  candidates[1][1] = parseInt(candidates[1][1]) + 1
  candidates[1][2] = 0
  candidates[2][0] = parseInt(candidates[2][0]) + 1
  candidates[2][1] = 0
  candidates[2][2] = 0
  return candidates.map((item) => item.join('.'))
}

export default {
  components: {
    radioButtonBase
  },
  data() {
    return {}
  },
  props: {
    value: String,
    oldVersion: String
  },
  computed: {
    checkedItem() {
      return this.value
    },
    candidates() {
      return newVersionCandidates(this.oldVersion)
    }
  },
  methods: {
    checkBool(value) {
      return this.checkedItem === value
    },
    updateValues(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  mounted() {
    this.$emit('input', this.candidates[0])
  }
}
</script>

<style lang="scss" scoped>
.version-select {
  &-title {
    margin: 0 0 $space-base;
  }
  &-body {
    display: flex;
    gap: $space-medium;
  }
}
</style>
