import { loadFile } from '@/lib/loadFile.js'

export default {
  methods: {
    async inputFile(f) {
      this.inputFileName = f.name
      const content = await loadFile(f)
      const textDecoder = new TextDecoder('utf-8')
      const contentStr = textDecoder.decode(content)
      this.requirementsError = null

      const dict = {}
      const rePackageName = /^[a-zA-Z0-9._-]+$/
      contentStr.split('\n').forEach((rawLine, _index) => {
        const line = rawLine.trim().split('#')[0]
        const index = _index + 1
        const parts = line
          .trim()
          .replace(/^-e/, '')
          .split('==')
          .map((part) => part.trim())

        if (parts.length === 1) {
          if (parts[0].trim() !== '') {
            if (rePackageName.test(parts[0].trim())) {
              // バージョン指定なしを禁止
              this.requirementsError = this.$t(
                'customblock.error.pleaseSpecifyVersionError',
                { index }
              )
              throw new Error(
                this.$t('customblock.error.requirementsTxtFormatError', {
                  index
                })
              )
            } else {
              // as URL
              try {
                const url = parts[0].trim()
                const filename = new URL(url).pathname
                  .split('/')
                  .pop()
                  .split('.')[0] // ファイル名
                dict[filename] = url
              } catch (e) {
                this.requirementsError = this.$t(
                  'customblock.error.requirementsTxtFormatError',
                  { index }
                )
                throw new Error(
                  this.$t('customblock.error.requirementsTxtFormatError', {
                    index
                  })
                )
              }
            }
          }
        } else if (parts.length === 2) {
          if (parts[1].trim() === '') {
            this.requirementsError = this.$t(
              'customblock.error.requirementsTxtFormatError',
              { index }
            )
            throw new Error(
              this.$t('customblock.error.requirementsTxtFormatError', { index })
            )
          }
          dict[parts[0]] = parts[1]
        } else {
          this.requirementsError = this.$t(
            'customblock.error.requirementsTxtFormatError',
            { index }
          )
          throw new Error(
            this.$t('customblock.error.requirementsTxtFormatError', { index })
          )
        }
      })

      this.requirements = dict
      this.$emit('input', this.requirements)
    }
  }
}
