<template>
  <div class="edit-wrap">
    <div class="title">
      <texts :text="$t('customblock.edit.content.params.detail.preview')" />
      <div
        v-tooltip="$t('customblock.edit.content.params.detail.previewTips')"
        class="icon"
      >
        <AtomsIcons iconName="info" size="small" />
      </div>
    </div>
    <div class="body">
      <OrganismsEditPreviewInner
        :value="values"
        @toggle-varidated-test="$emit('toggle-varidated-test', $event)"
      />
    </div>
  </div>
</template>

<script>
import OrganismsEditPreviewInner from './inner'
import AtomsIcons from '@/components/atoms/icon'

export default {
  components: {
    OrganismsEditPreviewInner,
    AtomsIcons
  },
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$space-small * 2});
  padding: $space-small;
  margin: $space-small $space-small $space-small 0;
  border: $border-sub;
  border-radius: adjustVW(8);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-text;
}
.body {
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 1;
  height: 100%;
  @include scrollbar;
}
.icon {
  cursor: help;
}
</style>
