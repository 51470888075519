<template>
  <div class="customblock-detail-code-wrap">
    <PrismEditor
      class="customblock-detail-code"
      :value="code"
      :highlight="highlighter"
      :readonly="readonly"
      line-numbers
      @input="$emit('update:code', $event)"
    />
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-python'
import 'prismjs/themes/prism-coy.min.css'

export default {
  components: {
    PrismEditor
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.python)
    }
  },
  props: {
    code: String,
    readonly: Boolean
  }
}
</script>

<style lang="scss" scoped>
.customblock-detail-code {
  overflow-y: scroll;
  padding-right: $space-base;
  line-height: 1.5;
  font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  font-size: $text-base;
  @include scrollbar;
  &-wrap {
    height: 100%;
    padding: $space-small $space-small $space-small $space-base;
    box-shadow: inset 0 0 0 #f4f8ff;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: $white !important;
    border-radius: 9in;
  }
}
</style>
