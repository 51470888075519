<template>
  <div class="customblock-side-wrap">
    <div class="customblock-side-body">
      <div v-if="editting === false">
        <div class="customblock-side-block">
          <div class="customblock-side-title">
            <texts :text="detail.name" size="large" />
          </div>
          <div class="customblock-side-desc">
            <description-long :title="detail.name" :text="detail.description" />
          </div>
          <button
            class="customblock-side-edit"
            :disabled="disabledEdit"
            :class="{ 'customblock-side-edit-disabled': disabledEdit }"
            @click="edit"
          >
            <text-with-icon
              :text="$t('datasetDetail.tableSide.edit')"
              iconName="edit"
              size="small"
            />
          </button>
          <div class="customblock-side-item-block">
            <text-with-title
              class="customblock-side-item-text"
              :text="$t('customblock.type.' + detail.block_type)"
              :title="$t('customblock.side.blockType')"
            />
          </div>
        </div>
        <div class="customblock-side-block">
          <div class="customblock-side-item-block-v">
            <text-with-title
              class="customblock-side-item-text"
              :text="detail.version"
              :title="$t('customblock.side.showingVersion')"
            />
            <description-long
              v-if="detail.release_note"
              class="customblock-side-item-text"
              :text="detail.release_note"
              :maxCount="150"
            />
          </div>
        </div>
        <div class="customblock-side-block">
          <div class="customblock-side-item-block">
            <text-with-title
              class="customblock-side-item-text"
              :text="detail.author_name"
              :title="$t('datasetDetail.tableSide.owner')"
            />
          </div>
          <div class="customblock-side-item-block">
            <div class="customblock-side-item-half">
              <text-with-title
                class="customblock-side-item-text"
                :title="$t('datasetDetail.tableSide.createdDate')"
              >
                <a-time :value="detail.created_at" />
              </text-with-title>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="customblock-side-summary-edit">
        <div class="customblock-side-summary-edit-title">
          <input-box-validation
            v-model="newName"
            :accept="validatorState.duplicate"
            :error="newName !== '' && !validatorState.duplicate"
            popupView
            isNameValidation
            :nameValidationSuggests="validatorState.suggests"
          />
        </div>
        <div class="customblock-side-summary-edit-desc">
          <input-box
            v-model="newDescription"
            class="customblock-side-summary-edit-desc-input"
            isTextArea
            isGray
          />
        </div>
        <div class="customblock-side-summary-edit-button-list">
          <div class="customblock-side-summary-edit-button-save">
            <button-main
              type="emphasis"
              :isDisabled="disabledSubmit"
              :text="$t('datasetDetail.tableSide.save')"
              @click="save"
            />
          </div>
          <div class="customblock-side-summary-edit-button-cancel">
            <button-main
              size="min"
              :text="$t('datasetDetail.tableSide.cancel')"
              @click="editting = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import descriptionLong from '@/components/atoms/description-long'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    descriptionLong,
    textWithTitle,
    textWithIcon,
    inputBox,
    inputBoxValidation,
    buttonMain
  },
  props: {
    detail: Object,
    writable: Boolean,

    validatorState: Object
  },
  computed: {
    disabledEdit() {
      return !this.writable
    },
    disabledSubmit: function () {
      if (this.newName === '') return true
      if (!this.validatorState.duplicate) return true

      return false
    }
  },
  data() {
    return {
      editting: false,

      newName: '',
      newDescription: ''
    }
  },
  watch: {
    newName: function (name) {
      this.$emit('input-edit-form', {
        name
      })
    }
  },
  methods: {
    edit() {
      this.editting = true
      this.newName = this.detail.name
      this.newDescription = this.detail.description

      this.$emit('input-edit-form', {
        name: this.newName
      })
    },
    async save() {
      await this.$emit('save-customblock', {
        name: this.newName,
        description: this.newDescription
      })
      this.editting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.customblock-side {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    padding: $space-small $space-base $space-small $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-inner {
    height: 100%;
  }
  &-tab {
    position: sticky;
    top: 0;
    margin: 0 0 $space-small;
    background: $background;
    z-index: 1;
  }
  &-block {
    &:not(:last-of-type) {
      padding: 0 0 $space-small;
      margin: 0 0 $space-small;
      border-bottom: $border-main;
    }
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-desc {
    margin: 0 0 $space-small;
  }
  &-edit {
    position: relative;
    display: block;
    margin: 0 0 0 auto;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      top: -$space-text;
      left: -$space-base;
      width: calc(100% + #{$space-base * 2});
      height: calc(100% + #{$space-text * 2});
      background: $medium-gray;
      border-radius: 9in;
      opacity: 0;
      z-index: -1;
      will-change: opacity;
      transition: $transition-base;
    }
    &:hover {
      opacity: 1;
    }
    &:hover::after {
      opacity: 1;
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &::after {
        content: inherit;
      }
    }
  }
  &-item {
    &-block {
      display: flex;
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
    }
    &-block-v {
      display: flex;
      flex-direction: column;
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
    }
    &-half {
      display: flex;
      width: calc((100% - #{$space-medium}) / 2);
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
      > div {
        margin: 0 $space-medium 0 0;
      }
    }
    &-text {
      width: 100%;
    }
  }
  &-summary-edit {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-title {
      margin: 0 0 $space-small;
    }
    &-desc {
      height: 100%;
      margin: 0 0 $space-small;
      &-input {
        height: 100%;
        > ::v-deep div {
          height: 100%;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &-save {
        margin: 0 $space-base 0 0;
      }
    }
  }
}

.now-developing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-image {
    width: 100%;
    height: auto;
    margin-bottom: $space-medium;
  }
}
</style>
