<template>
  <div>
    <div class="c-sidebar">
      <div class="c-sidebar-top">
        <div class="c-sidebar-top-link">
          <router-link
            v-if="$route.name == 'customblockList'"
            :to="{ name: 'projectList' }"
          >
            <text-with-icon
              :text="$t('datasetDetail.detailSidebar.backward')"
              iconName="backward"
              size="small"
            />
          </router-link>
          <router-link v-else :to="{ name: 'customblockList' }">
            <text-with-icon
              :text="$t('customblock.backward')"
              iconName="backward"
              size="small"
            />
          </router-link>
        </div>
        <div>
          <text-with-icon
            :text="$t('recipe.layerType.customblock')"
            iconName="customblock"
            size="large"
          />
          <texts
            class="c-sidebar-text"
            isShowAll
            size="min"
            color="gray"
          >
            {{ $t('customblock.side.description') }}
          </texts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
export default {
  components: {
    texts,
    textWithIcon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// サイドバー
.c-sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: adjustVW(240); // 12.5%
  height: 100vh;
  padding: 0 $space-small;
  background: $background;
  z-index: 1000;
  &-top {
    &-link {
      display: flex;
      align-items: center;
      height: adjustVH(80);
      > a {
        overflow: hidden;
      }
    }
  }
  &-text {
    margin-top: $space-small;
    word-break: break-all;
    white-space: pre-line;
  }
}
</style>
