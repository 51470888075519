<template>
  <div
    class="list"
    :class="{ 'list-used': isUsed }"
    :style="{ '--line': line }"
  >
    <template v-for="(layer, index) in layers">
      <span :key="index" class="item" :class="{ 'item-no-wrap': noWrap }">
        <preprocessing-chart-block-min :layer="layer" :isUsed="isUsed" />
        <span v-if="index !== layers.length - 1" class="line" />
      </span>
    </template>
  </div>
</template>

<script>
import preprocessingChartBlockMin from '@/components/atoms/preprocessing-chart-block-min'

export default {
  components: {
    preprocessingChartBlockMin
  },
  props: {
    /** 前処理のブロックの配列 */
    layers: Array,
    /** 前処理を適用済みかどうか */
    isUsed: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 前処理を複数行に表示しない */
    noWrap: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 行数を指定する場合 */
    line: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  --line: 3;
  overflow: hidden;
  display: -webkit-box;
  color: $background-decoration;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--line);
  -webkit-box-orient: vertical;
  &-used {
    color: $border-gray;
  }
}

.item-no-wrap {
  white-space: nowrap;
}

.line {
  display: inline-block;
  width: adjustVW(16);
  height: adjustVW(2);
  background: $line-emphasis;
  vertical-align: middle;
  transform: translateY(adjustVW(-1));
  .list-used & {
    background: $border-gray;
  }
}
</style>
