<!--
  画面上に大きく表示するリストに使用するメニュー一覧
-->
<template>
  <transition-toggle-contents>
    <div v-if="isMenuShow" class="menu-wrap">
      <template v-for="menu in menus">
        <div
          v-if="isCheckedMultiple(menu.isMultiple) && !isHideItem(menu)"
          :key="menu.id"
          class="menu-inner"
          :class="{ 'menu-inner-disabled': isDisabled(menu) }"
          @mouseenter="isHoverIcon(menu.id)"
          @mouseleave="isLeaveIcon()"
        >
          <icons
            v-tooltip="{
              content: isDisabled(menu)
                ? menu.tips.disabledName
                : menu.tips.name,
              trigger: ['manual'],
              show: isTipsShow === menu.id,
              delay: { show: 500, hide: 500 }
            }"
            :iconName="menu.iconName"
            isButton
            :isDisabled="isDisabled(menu)"
            class="menu-icon"
            @icon-click="menuClick(menu.value)"
          />
        </div>
      </template>
    </div>
  </transition-toggle-contents>
</template>

<script>
import icons from '@/components/atoms/icon'
import transitionToggleContents from './transition-toggle-contents'

export default {
  components: {
    icons,
    transitionToggleContents
  },
  data() {
    return {
      isTipsShow: ''
    }
  },
  props: {
    /** 表示するアイコンメニュー
     * id: id,
     * iconName: 表示するアイコン名
     * value: emitする値
     * disabled: 使用不可にする
     * isMultiple: 複数チェックが入っているときにも表示
     * hideItem: アイコンを非表示にする
     * tips: アイコンの上に表示するテキスト
     *  tips.diabledName: 使用不可のときに表示するTips
     *  tips.name: 通常のTips
     */
    menus: Array,
    /** 現在選択しているメニュー */
    activeMenu: String,
    /** アイコンメニューを表示するかどうか */
    isMenuShow: Boolean,
    /** チェックボックスの選択数 */
    checked: Array
  },
  methods: {
    menuClick(value) {
      this.$emit('menu-click', { activeMenu: value })
    },
    isCheckedMultiple(e) {
      if (this.checked.length > 1) {
        // 複数選択をしているかどうか
        if (e) {
          // 複数選択を許容しているかどうか
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    isHoverIcon(e) {
      this.isTipsShow = e
    },
    isLeaveIcon() {
      this.isTipsShow = ''
    },
    isDisabled(menu) {
      if (!menu?.disabled) return false
      if (menu.disabled) {
        return true
      } else {
        return false
      }
    },
    isHideItem(menu) {
      if (!menu?.hideItem) return false
      if (menu.hideItem) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &-wrap {
    display: flex;
  }
  &-inner {
    position: relative;
    width: adjustVW(48);
    height: adjustVW(48);
    margin: 0 $space-base 0 0;
    background: $background-sub;
    border-radius: 9in;
    transition: all $transition-base;
    &:last-child {
      margin: 0;
    }
    &:hover {
      background: $medium-gray;
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }
  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      &::v-deep button {
        opacity: 1;
      }
    }
  }
}
</style>
