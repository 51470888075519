<!--
  背景が敷かれていない、テキストとアイコンのみのボタン
  ホバーした際に色が変わり、押下した際にも色が変わる
-->
<template>
  <div class="text-button">
    <button
      class="text-button-button"
      :class="{
        'text-button-button-white': isBackWhite,
        'text-button-button-disabled': isDisabled
      }"
      :disabled="isDisabled"
      @click="$emit('click')"
    >
      <text-with-icon
        :text="text"
        :iconName="iconName"
        :size="size"
        :color="isDisabled ? 'gray' : 'default'"
      />
    </button>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    textWithIcon
  },
  props: {
    /** 表示するテキスト */
    text: String,
    /** 表示するアイコン */
    iconName: String,
    /** 背景色を白にする */
    isBackWhite: Boolean,
    /** ボタンの大きさtextsやiconsと同じ。または数値で入力可能 */
    size: {
      type: [String, Number],
      require: false,
      default: 'default'
    },
    /** 使用不可状態 */
    isDisabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.text-button {
  &-button {
    padding: $space-base $space-small;
    background: $background-sub;
    border-radius: 9in;
    transition: $transition-base;
    &:hover {
      background: $medium-gray;
      opacity: 1;
    }
    &:focus {
      background: $background-text-button;
    }
    &-white {
      background: $background;
    }
    &-disabled {
      cursor: not-allowed;
      &:hover {
        background: $background-sub;
        opacity: 1;
      }
    }
  }
}
</style>
