<template>
  <div class="param-list-wrap">
    <div class="title">
      <texts :text="$t('customblock.parameter')" size="large" />
      <button class="list-button" @click="$emit('add')">
        <text-with-icons
          iconName="plus"
          :text="$t('customblock.edit.addParamsButton')"
          size="small"
          color="emphasis"
        />
      </button>
    </div>
    <div class="param-list">
      <params-item
        v-for="(item, valiableName) in params"
        :key="valiableName"
        class="params-item"
        :valiableName="valiableName"
        :item="item"
        @edit="$emit('edit', valiableName)"
        @remove="$emit('remove', valiableName)"
      />
    </div>
  </div>
</template>
<script>
import textWithIcons from '@/components/molecules/text-with-icon'
import texts from '@/components/atoms/text'
import paramsItem from './params-item'

export default {
  components: {
    textWithIcons,
    texts,
    paramsItem
  },
  props: {
    params: Object
  }
}
</script>

<style lang="scss" scoped>
.param-list {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: $space-sub;
  @include scrollbar;
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.param-item {
  &:not(:last-of-type) {
    padding-bottom: $space-small;
    margin-bottom: $space-small;
    border-bottom: $border-sub;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-base;
}
</style>
