<template>
  <div>
    <customblockDetail
      :detail="detail"
      :history="history"
      :loading="detailLoading"
      :notFound="notFound"
      :historyLoading="historyLoading"
      :writable="writable"
      :validatorState="validatorState"
      :popup="popup"
      :waitPageLoading="waitPageLoading"
      :waitProcessLoading="waitProcessLoading"
      @version-click="versionClick"
      @input-edit-form="updateValidator"
      @save-customblock="saveCustomBlock"
      @edit-customblock="editCustomBlock"
      @menu-click="menuClick"
      @close-modal="closePopup"
      @delete-customblock="deleteCustomBlock"
      @goto-recipe="gotoRecipe"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customblockDetail from '@/components/templates/customblock-detail.vue'
import { duplicateValidator } from '../lib/validator/customblock'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    customblockDetail
  },
  mixins: [setMountedTimer],
  data() {
    return {
      detailLoading: false,
      notFound: false,
      historyLoading: false,
      detail: {},
      history: [],
      validatorState: {
        duplicate: true,
        suggest: []
      },
      popup: {
        showPopup: [],
        delete: {}
      },
      pageLoading: false,
      deleteLoading: false,
      downloadLoading: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.pageLoading = true
      await vm.fetchAccountInfo()
      if (vm.accountInfo.plan !== 'basic') {
        vm.showPopup('freePlan')
        return
      }
      const id = vm.$route.params.id
      const version = vm.$route.params.version
      await vm.fetchDetail(id, version)
      await vm.fetchHistory(id)
      await vm.fetchCustomblocks()
      vm.pageLoading = false
    })
  },
  beforeRouteUpdate(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      const id = vm.$route.params.id
      const version = vm.$route.params.version
      await vm.fetchDetail(id, version)
    })
  },
  computed: {
    ...mapGetters('customblock', ['customblockList', 'loading', 'submitting']),
    ...mapGetters('auth', ['accountInfo']),
    writable() {
      const notYours = this.detail.author_id !== this.accountInfo.accountId
      return !(notYours && this.accountInfo?.organizationInfo?.role !== 'admin')
    },
    waitPageLoading() {
      return this.loading || this.pageLoading || this.detailLoading
    },
    waitProcessLoading() {
      return this.deleteLoading || this.downloadLoading
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('customblock', ['fetchCustomblocks', 'downloadCustomblockLog']),
    async fetchDetail(id, version = undefined) {
      this.detailLoading = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId: id,
          version
        })
        if (res.status === 'error') {
          this.notFound = true
          return
        }
        this.detail = res.result
      } finally {
        this.detailLoading = false
      }
    },
    async fetchHistory(id) {
      this.historyLoading = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockHistory',
          customBlockId: id
        })
        this.history = res.result
      } finally {
        this.historyLoading = false
      }
    },
    async versionClick(version) {
      const id = this.$route.params.id

      this.$router.replace({
        name: 'customblockDetail',
        params: {
          id,
          version
        }
      })
      await this.fetchDetail(id, version)
    },
    updateValidator({ name }) {
      if (this.detail.name === name) {
        this.validatorState = {
          duplicate: true,
          suggest: []
        }
      } else {
        this.validatorState = duplicateValidator(this.customblockList, name)
      }
    },
    async saveCustomBlock(payload) {
      const customBlockId = this.$route.params.id
      const version = this.$route.params.version

      const res = await this.$sendMessageAndReceive({
        action: 'updateCustomBlock',
        customBlockId,
        name: payload.name,
        description: payload.description
      })
      if (res.status === 'error') {
        throw res
      }
      this.fetchCustomblocks()
      this.fetchDetail(customBlockId, version)
    },
    async menuClick(menu) {
      const customBlockId = this.$route.params.id
      const version = this.detail?.version
      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている

      switch (menu.activeMenu) {
        case 'downloadLog': {
          const timeoutDownload = window.setTimeout(
            function () {
              this.downloadLoading = true
            }.bind(this),
            100
          )
          try {
            await this.downloadCustomblockLog({
              customblockId: customBlockId,
              version
            })
          } finally {
            window.clearTimeout(timeoutDownload)
            this.downloadLoading = false
          }
          break
        }
        case 'delete':
          this.popup.delete = {
            target: customBlockId
          }
          this.showPopup('delete')
          break
      }
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    async deleteCustomBlock() {
      this.deleteLoading = true
      const customBlockId = this.$route.params.id

      const res = await this.$sendMessageAndReceive({
        action: 'deleteCustomBlock',
        customBlockId
      })
      if (res.status === 'error') {
        throw res
      }
      this.deleteLoading = false
      this.$router.push({
        name: 'customblockList'
      })
    },
    editCustomBlock() {
      const customBlockId = this.$route.params.id
      this.$router.push({
        name: 'customblockEdit',
        params: {
          id: customBlockId
        }
      })
    },
    async gotoRecipe({ id, accountId }) {
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: id,
        recipe_account_id: accountId
      })

      const projectId = res.detail.projectId

      this.$router.push({
        name: 'recipeProjectDetail',
        params: {
          projectId,
          id: `${id}-${accountId}`
        }
      })
    }
  }
}
</script>
