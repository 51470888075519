<template>
  <div
    class="text"
    :class="['text-' + color]"
    :style="{
      '--max-width': calcMaxWidth
    }"
  >
    <texts
      :text="text"
      :size="size"
      :color="color"
      :isBold="isBold"
    />
    <slot />
  </div>
</template>

<script>
import { checkErrori18n } from '@/lib/misc.js'

export default {
  props: {
    /** 表示するテキスト */
    text: {
      type: [String, Number, Boolean],
      default: ''
    },
    /** 文字の大きさ ['default', 'title', 'sub-title', 'big', 'large', 'small', 'min'] */
    size: {
      type: [String, Number],
      default: 'min',
      require: false
    },
    /** 文字の色やデコレーション ['default', 'gray', 'emphasis', 'caution', 'accept', 'disabled', 'off', 'green'] */
    color: {
      type: String,
      require: false,
      default: 'default'
    },
    /** 太文字かどうか */
    isBold: {
      type: Boolean,
      default: false,
      require: false
    },
    /** dot-textの最大表示領域をpxで渡す VWに置換されます */
    maxWidth: {
      type: Number,
      default: 120,
      require: false
    }
  },
  computed: {
    calcMaxWidth() {
      return (this.maxWidth / 1920) * 100 + 'vw'
    },
    disabledTips() {
      if (!this.isDisabled) return null
      const reason = checkErrori18n.bind(this)(this.disabledReason)
      return {
        content: reason,
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  --max-width: adjustVW(120);
  max-width: var(--max-width);
  padding: adjustVW(5) $space-base $space-text;
  border-radius: adjustVW(4);
  &-default {
    background-color: $background-sub;
  }
  &-green {
    background-color: $green-lite;
  }
  &-gray {
    background-color: $background-sub;
  }
  &-emphasis {
    background-color: $background-decoration;
  }
  &-caution {
    background-color: $red-bg;
  }
  &-accept {
    background-color: $blue-bg;
  }
  &-disabled {
    background-color: $background-sub;
  }
  &-off {
    background-color: $background-sub;
  }
}
</style>
