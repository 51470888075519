<template>
  <texts
    :size="size"
    :text="dispValue"
    :isBold="isBold"
    :color="color"
  />
</template>

<script>
import { toPercentage, toRounded } from '@/lib/misc.js'

export default {
  props: {
    /** パーセント表示するかどうか */
    percentage: Boolean,
    /** 精度の数値 */
    value: Number,
    /** 表示サイズ（textsコンポーネントと同じです） */
    size: [String, Number],
    /** 太字にするかどうか */
    isBold: Boolean,
    /** 色（textsコンポーネントと同じです） */
    color: String
  },
  computed: {
    dispValue() {
      if (this.percentage) {
        return toPercentage(this.value)
      } else {
        return toRounded(this.value)
      }
    }
  }
}
</script>
