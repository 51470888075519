<template>
  <div class="customblock-detail-wrap">
    <top
      class="customblock-detail-top"
      :detail="detail"
      :blockType="detail.block_type"
      @save-click="$emit('save-click', newDetail)"
      @menu-click="$emit('menu-click', $event)"
      @select-type="selectType"
    />
    <bottom
      class="customblock-detail-table"
      :code.sync="code"
      :params="params"
      :requirements="detail.requirements"
      @update-param="updateParam"
      @remove-param="removeParam"
    />
  </div>
</template>

<script>
import top from './top'
import bottom from './bottom'

export default {
  components: {
    top,
    bottom
  },
  data() {
    return {
      code: this.detail.code ?? '',
      params: this.detail.params ?? {}
    }
  },
  methods: {
    updateParam(e) {
      const name = e.name
      const params = e.params
      this.$delete(this.params, name)
      this.$set(this.params, params.variableName, params)
    },
    removeParam(e) {
      this.$delete(this.params, e)
    },
    selectType(e) {
      this.blockType = e
    }
  },
  computed: {
    newDetail() {
      return {
        code: this.code,
        params: this.params
      }
    }
  },
  props: {
    detail: Object
  },
  watch: {
    code() {
      this.$emit('editted')
    },
    params() {
      this.$emit('editted')
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-list {
  &-block {
    position: relative;
    height: 100%;
  }
  &-list {
    height: 100%;
  }
}

.customblock-detail {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'top'
      'main';
    grid-template-rows: auto 1fr;
    height: 100vh;
    padding: 0 $space-medium $space-medium;
  }

  &-top {
    grid-area: top;
  }

  &-table {
    grid-area: main;
  }
}
</style>
