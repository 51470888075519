<template>
  <div key="datasetList" class="dataset-list-block">
    <table-list
      v-model="checked"
      class="dataset-list-list"
      :items="listTranslated"
      :field="fields"
      :noSearchResults="noSearchResults"
      isButton
      defaultSortedBy="updateTime"
      idColumn="customblock_id"
      @label-click="$emit('sort-label', { label: $event })"
      @list-click="$emit('show-customblock', $event)"
    />
  </div>
</template>

<script>
import tableList from '@/components/molecules/table-list'

export default {
  components: {
    tableList
  },
  data() {
    return {
      checked: []
    }
  },
  computed: {
    fields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('customblock.list.table.name'),
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'latest_version',
          label: this.$t('customblock.list.table.latestVersion'),
          thClass: 'default',
          listType: 'default',
          sortable: true
        },
        {
          id: 2,
          key: 'translated_type',
          label: this.$t('customblock.side.blockType'),
          thClass: 'default',
          listType: 'default',
          sortable: true
        },
        {
          id: 3,
          key: 'author_name',
          label: this.$t('datasetList.datasetList.accountId'),
          thClass: 'large',
          listType: 'default',
          sortable: true
        },
        {
          id: 4,
          key: 'updated_at',
          label: this.$t('common.updateTime'),
          thClass: 'default',
          listType: 'time',
          sortable: true
        }
      ]
    },
    listTranslated() {
      return this.list.map((item) => {
        return {
          translated_type: this.$t('recipe.layerType.' + item.block_type),
          ...item
        }
      })
    }
  },
  props: {
    list: Array,
    noSearchResults: Boolean
  },
  watch: {
    checked(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-list {
  &-block {
    position: relative;
    height: 100%;
  }
  &-list {
    height: 100%;
  }
}
</style>
