<template>
  <div>
    <texts
      class="title"
      color="gray"
      :text="values.name"
      size="small"
    />
    <div class="input">
      <AtomsCheckBox
        class="input-item"
        :value="values.params.value"
        :checked="values.params.value"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import AtomsCheckBox from '@/components/atoms/checkbox-base'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    AtomsCheckBox,
    texts
  },
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateValue(value) {
      const res = JSON.parse(JSON.stringify(this.values))
      res.params.value = value
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: adjustVW(20);
}
.input {
  width: fit-content;
  height: fit-content;
}
</style>
