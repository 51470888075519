<template>
  <div>
    <div class="item">
      <div v-tooltip="mixinErrorTips" class="item-half">
        <div>
          <MoleculesInputBox
            v-model="internalValue.value"
            :title="$t('customblock.edit.content.params.detail.default')"
            :min="internalValue.min"
            :max="internalValue.max"
            type="number"
            required
            isGray
            @focus="mixinToggleFocus('default')"
          />
        </div>
      </div>
    </div>
    <div class="item">
      <div v-tooltip.bottom="mixinErrorTipsMin">
        <div>
          <MoleculesInputBox
            v-model="internalValue.min"
            type="number"
            :title="$t('customblock.edit.content.params.detail.min')"
            :max="internalValue.max"
            :error="mixinIsMinLargerMax"
            isGray
          />
        </div>
      </div>
      <div v-tooltip.bottom="mixinErrorTipsMax">
        <div>
          <MoleculesInputBox
            v-model="internalValue.max"
            :title="$t('customblock.edit.content.params.detail.max')"
            type="number"
            :min="internalValue.min"
            :error="mixinIsMaxSmallerMin"
            isGray
            @focus="mixinToggleFocus('max')"
          />
        </div>
      </div>
    </div>
    <div class="item-wrap">
      <div class="item-wrap">
        <!-- TODO 可能であればトランジッション作る -->
        <div
          v-for="(extra, index) in internalValue.extra_selections"
          :key="index"
          class="item-inner"
        >
          <div class="item-content">
            <div class="title">
              <texts
                :text="
                  $t('customblock.edit.content.params.detail.extra') +
                    (index + 1)
                "
                color="gray"
                size="small"
              />
              <div v-if="deleteTarget !== index" class="item-icon">
                <AtomsIcons
                  color="caution"
                  icon-name="delete"
                  is-button
                  size="small"
                  @icon-click="checkDelete(index)"
                />
              </div>
            </div>
            <div v-tooltip="duplicateTips(index)">
              <MoleculesInputBox
                v-model="internalValue.extra_selections[index]"
                required
                isGray
              />
            </div>
          </div>
          <MoleculesDeleteParams
            :is-show="deleteTarget === index"
            @confirm-delete="confirmDelete(index)"
            @discard-delete="discardDelete"
          />
        </div>
        <button
          class="item-add"
          :class="{
            'item-disabled': disabledAdd
          }"
          :disabled="disabledAdd"
          @click="disabledAdd ? '' : addBox()"
        >
          <MoleculesTextWithIcon
            iconName="plus"
            size="small"
            :text="$t('customblock.edit.content.params.detail.add')"
            :color="disabledAdd ? 'gray' : 'black'"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import numericParamsSetting from '@/mixin/numeric-params-setting.js'

import AtomsIcons from '@/components/atoms/icon'
import MoleculesInputBox from '@/components/molecules/input-box'
import MoleculesDeleteParams from '@/components/molecules/delete-params/delete-params'
import MoleculesTextWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    MoleculesInputBox,
    MoleculesTextWithIcon,
    MoleculesDeleteParams,
    AtomsIcons
  },
  mixins: [numericParamsSetting],
  data() {
    return {
      deleteTarget: null
    }
  },
  computed: {
    disabledAdd() {
      if (
        !this.internalValue.extra_selections ||
        this.internalValue.extra_selections.length === 0
      )
        return false
      return this.internalValue.extra_selections.some((option) => {
        return !option
      })
    }
  },
  watch: {
    'internalValue.extra_selections': {
      handler(newVal) {
        const check = new Set(newVal).size !== newVal.length
        this.$emit('set-error', check)
      }
    }
  },
  methods: {
    checkDelete(index) {
      this.deleteTarget = index
    },
    confirmDelete(index) {
      this.internalValue.extra_selections.splice(index, 1)
      this.deleteTarget = null
    },
    discardDelete() {
      this.deleteTarget = null
    },
    addBox() {
      const defaultIndex = this.internalValue.extra_selections.length + 1
      const defaultValue =
        this.$t('customblock.edit.content.params.detail.defaultValueText') +
        defaultIndex
      this.internalValue.extra_selections.push(defaultValue)
    },
    duplicateTips(index) {
      const target = this.internalValue.extra_selections[index]
      const check = this.internalValue.extra_selections.some(
        (item, itemIndex) => {
          return item === target && index !== itemIndex && index > itemIndex
        }
      )
      return {
        content: this.$t(
          'customblock.edit.content.params.detail.isNoDuplicate'
        ),
        trigger: 'manual',
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 },
        show: check
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
  grid-column-gap: $space-small;
  margin-bottom: $space-sub;
}
.item {
  display: flex;
  grid-column-gap: $space-small;
  &:not(:last-of-type) {
    margin-bottom: $space-medium;
  }
  &-inner {
    display: flex;
    align-items: center;
    grid-column-gap: $space-small;
    width: 100%;
    margin-bottom: $space-small;
  }
  &-content {
    width: 100%;
  }
  &-half {
    width: calc(50% - #{$space-small / 2});
  }
  &-add {
    width: fit-content;
    margin-top: $space-base;
    cursor: pointer;
    @include buttonHover;
  }
  &-disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
