<template>
  <div>
    <texts
      class="title"
      :text="values.name"
      color="gray"
      size="small"
    />
    <div v-tooltip="tipsInteger">
      <div v-tooltip="errorTips" class="input">
        <MoleculesInputBox
          :value="internalValue"
          :min="values.params.min"
          :max="values.params.max"
          type="number"
          required
          isGray
          @blur="updateValue"
          @input="checkValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import numericParamPrevie from '@/mixin/numeric-param-preview'

import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  components: {
    MoleculesInputBox
  },
  mixins: [numericParamPrevie],
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: null,
      showTipsInteger: false
    }
  },
  watch: {
    'values.params.value'(newVal) {
      this.internalValue = newVal
    }
  },
  mounted() {
    this.internalValue = this.values.params.value
  },
  computed: {
    tipsInteger() {
      return {
        content: this.$t('customblock.edit.content.params.detail.isNotInt'),
        trigger: 'manual',
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 },
        show: this.showTipsInteger
      }
    }
  },
  methods: {
    checkValue(value) {
      if (!value || value === 0 || Number.isInteger(value)) {
        this.showTipsInteger = false
      } else {
        this.showTipsInteger = true
      }
      this.internalValue = value
    },
    updateValue() {
      const res = JSON.parse(JSON.stringify(this.values))
      let resValue = this.internalValue
      if (isNaN(resValue)) {
        resValue = null
      } else if (!Number.isInteger(resValue)) {
        resValue = Math.ceil(resValue)
        this.showTipsInteger = false
      }
      res.params.value = resValue
      this.internalValue = resValue
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-text;
}
</style>
