<template>
  <div class="wrap">
    <div v-if="checkRecipeExist" class="caution-wrap">
      <texts
        class="caution-text"
        :text="$t('customblock.popup.delete.cautionMessage')"
        color="caution"
        size="small"
      />
    </div>
    <div class="items-wrap">
      <list-item :items="list" :field="field" isToggle>
        <template v-slot:toggleContent="{ entry }">
          <div v-if="entry.description" class="title-wrap">
            <div>
              <description :text="entry.description" />
            </div>
          </div>
          <slot name="toggleContent" v-bind:entry="entry">
            <div class="block">
              <div
                v-if="
                  entry.recipes_using != null && entry.recipes_using.length > 0
                "
                class="inner"
              >
                <div class="item">
                  <texts
                    class="title"
                    :text="$t('customblock.popup.delete.usedRecipes')"
                    size="small"
                    color="gray"
                  />
                  <div class="list">
                    <text-with-icon
                      v-for="(recipe, key) in limitCount(
                        entry.recipes_using,
                        4
                      )"
                      :key="key"
                      class="list-item"
                      :text="recipe.name"
                      iconName="newTab"
                      pos="right"
                      @click.native.stop="
                        $emit('goto-recipe', {
                          id: recipe.recipe_id,
                          accountId: recipe.account_id
                        })
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="inner">
                <text-with-title
                  :title="$t('customblock.blockType')"
                  :text="$t('customblock.type.' + entry.block_type)"
                />
              </div>
            </div>
            <div class="block">
              <div class="inner">
                <text-with-title
                  :title="$t('common.owner')"
                  :text="entry.author_name"
                />
              </div>
            </div>
            <div class="block">
              <div class="inner">
                <text-with-title :title="$t('common.createTime')">
                  <a-time :value="entry.createTime" />
                </text-with-title>
              </div>
              <div class="inner">
                <text-with-title :title="$t('common.updateTime')">
                  <a-time :value="entry.updateTime" />
                </text-with-title>
              </div>
            </div>
          </slot>
        </template>
      </list-item>
    </div>
    <div class="footer">
      <div class="button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :isDisabled="disabledClick"
          @click="$emit('close-modal')"
        />
      </div>
      <div class="button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :isDisabled="disabledClick"
          @click="deleteSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
import listItem from '@/components/molecules/list-item'

export default {
  components: {
    description,
    buttonMain,
    textWithTitle,
    textWithIcon,
    listItem
  },
  computed: {
    field() {
      return [
        {
          label: this.$t('customblock.list.table.deleteCustomBlock'),
          key: 'name'
        },
        {
          key: 'caution',
          label: '',
          isCaution: true
        }
      ]
    },
    list() {
      if (!this.targets) return []
      const target = this.targets.map((item) => {
        return {
          ...item,
          caution:
            item.recipes_using.length > 0
              ? this.$t('customblock.popup.delete.usedCaution')
              : ''
        }
      })
      return target
    },
    checkRecipeExist() {
      if (!this.targets) return false
      const target = this.targets.filter(
        (target) => target.recipes_using.length > 0
      )
      return target.length > 0
    }
  },
  props: {
    /** 削除するターゲット */
    targets: Array,
    /** ボタンを押せないようにする */
    disabledClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteSubmit() {
      this.$emit('delete-customblock', this.targets)
    },
    limitCount(arr) {
      if (!arr || arr.length === 0) return []
      if (arr.length > 4) {
        return arr.slice(0, 4)
      } else {
        return arr
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(720);
  height: 100%;
  .items-wrap {
    overflow-y: scroll;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    margin: 0 $space-medium 0 0;
    &:last-of-type {
      margin: 0;
    }
  }
}
.block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $space-medium;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: $space-medium;
  }
}
.title {
  margin: 0 0 $space-text;
  &-wrap {
    margin: -$space-base 0 $space-medium;
  }
}
.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-row-gap: $space-text;
  &-item {
    overflow: hidden;
    width: fit-content;
    max-width: 100%;
    text-decoration: underline;
    cursor: pointer;
    @include buttonHover;
  }
}
.inner {
  overflow: hidden;
}

.caution {
  &-wrap {
    margin-bottom: $space-small;
  }
  &-text {
    white-space: pre-line;
  }
}
</style>
