export const PARAMS_KEYS = {
  INT: 'int',
  NUMBER: 'number',
  SELECT: 'select',
  NUMBER_SELECT: 'number_select',
  BOOL: 'bool',
  CHECKBOX: 'checkbox',
  TEXT: 'text'
}

export const paramsTemplate = {
  [PARAMS_KEYS.INT]: {
    value: 0,
    min: null,
    max: null
  },
  [PARAMS_KEYS.NUMBER]: {
    value: 0,
    min: null,
    max: null
  },
  [PARAMS_KEYS.SELECT]: {
    value: 'default',
    options: [
      {
        value: 'default',
        text: 'デフォルト'
      }
    ]
  },
  [PARAMS_KEYS.NUMBER_SELECT]: {
    value: 0,
    min: null,
    max: null,
    extra_selections: []
  },
  [PARAMS_KEYS.BOOL]: {
    value: true
  },
  [PARAMS_KEYS.CHECKBOX]: {
    // emit時にvalueの配列にcheckがついているものを入れる必要がある
    options: [
      {
        value: 'default',
        text: 'デフォルト',
        check: false
      }
    ]
  },
  [PARAMS_KEYS.TEXT]: {
    value: ''
  }
}
