<template>
  <div class="param-item">
    <texts
      class="c-textblock-title"
      color="green"
      :text="name"
      :size="size"
    />
    <texts
      class="c-textblock-content"
      color="gray"
      :text="version"
      :size="size"
    />
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    version: String,
    size: {
      type: [Number, String],
      default: 13
    }
  }
}
</script>

<style lang="scss" scoped>
.param-item {
  display: flex;
  justify-content: space-between;
  grid-column-gap: $space-base;
}
.c-textblock {
  &-title {
    overflow: hidden;
  }
  &-content {
    flex-shrink: 0;
  }
}
</style>
