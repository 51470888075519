<template>
  <input-box
    v-model="searchText"
    isSearch
    :title="title"
    :placeholder="placeholder"
    :isGray="gray"
    @input="inputKeyword"
    @change="changeKeyword"
  />
</template>

<script>
import inputBox from '@/components/molecules/input-box'

import Fuse from 'fuse.js'

export default {
  components: {
    inputBox
  },
  data() {
    return {
      showItems: [],
      fuse: null,
      searchText: '',
      noSearchResults: false
    }
  },
  props: {
    /**
     * 背景色設定
     */
    gray: {
      default: false,
      type: Boolean
    },
    /** 検索対象 */
    value: {
      type: Array,
      default: () => []
    },
    /** タイトル */
    title: String,
    /** プレイスホルダー */
    placeholder: String,
    /** fuseのオプションを設定できるprops */
    option: {
      default: () => ({
        includeScore: true,
        shouldSort: true
      }),
      type: Object
    }
  },
  methods: {
    inputKeyword: function () {
      this.noSearchResults = false
      if (this.searchText !== '') {
        const results = this.fuse.search(this.searchText)
        this.showItems = results.map((result) => result.item)
        if (this.showItems.length === 0) {
          this.noSearchResults = true
        }
        this.$emit('input', {
          result: this.showItems,
          value: this.searchText,
          noSearchResults: this.noSearchResults
        })
      } else {
        this.showItems = this.items
        this.$emit('input', {
          result: this.showItems,
          value: this.searchText,
          noSearchResults: this.noSearchResults
        })
      }
    },
    changeKeyword: function () {
      this.noSearchResults = false
      if (this.searchText !== '') {
        const results = this.fuse.search(this.searchText)
        this.showItems = results.map((result) => result.item)
        if (this.showItems.length === 0) {
          this.noSearchResults = true
        }
        this.$emit('change', {
          result: this.showItems,
          value: this.searchText,
          noSearchResults: this.noSearchResults
        })
      } else {
        this.showItems = this.items
        this.$emit('change', {
          result: this.showItems,
          value: this.searchText,
          noSearchResults: this.noSearchResults
        })
      }
    },
    // fuseの検索対象をリフレッシュする場合に使用する
    refreshFuse() {
      this.fuse = new Fuse(this.value, this.option)
      this.inputKeyword()
    }
  },
  mounted() {
    this.showItems = this.value
    this.fuse = new Fuse(this.showItems, this.option)
  }
}
</script>
