<template>
  <div class="c-wrap">
    <sidebar-customblock />
    <div class="c-body-wrap">
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="waitPageLoading" key="loading" />
          <page-not-found v-else-if="notFound" />
          <customblockEdit
            v-else
            :detail="detail"
            @editted="$emit('editted', $event)"
            @save-click="$emit('save-click', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="waitProcessLoading" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="dataset-list-popup">
      <Popup
        v-bind="popup"
        :customBlockDetail="detail"
        :validatorState="validatorState"
        :disabledClick="waitProcessLoading"
        @close-modal="$emit('close-modal', $event)"
        @save-customblock="$emit('save-customblock', $event)"
        @validation-change="$emit('validation-change', $event)"
        @select-type="$emit('select-type', $event)"
      />
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import SidebarCustomblock from '@/components/organisms/sidebar-customblock.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import customblockEdit from '@/components/organisms/customblock-edit/main.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import PageNotFound from '@/components/organisms/page-not-found'
import Popup from '@/components/organisms/customblock-edit/popup'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    SidebarCustomblock,
    hoverMenu,
    loadingProcessing,
    customblockEdit,
    PageNotFound,
    Popup,
    transitionPage,
    TransitionToggleContents
  },
  props: {
    detail: Object,
    popup: Object,
    validatorState: Object,
    notFound: Boolean,
    waitProcessLoading: Boolean,
    waitPageLoading: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
