<template>
  <div class="save-wrap">
    <div class="save-main">
      <div class="save-item">
        <version-select v-model="version" :oldVersion="this.detail.version" />
      </div>
      <div class="save-item">
        <input-box
          v-model="releaseNote"
          isGray
          isTextArea
          :title="$t('customblock.releaseNote')"
        />
      </div>
      <div class="save-item">
        <upload-requirements
          v-model="requirements"
          @set-error="setRequirementsError"
        />
      </div>
      <div class="save-foot">
        <button-large
          :isDisabled="disabledSubmit || disabledClick"
          :text="$t('customblock.popup.saveNew.title')"
          @click="saveCustomBlock"
        />
      </div>
    </div>
    <div class="save-detail">
      <div class="save-detail-inner">
        <codeBox class="codebox" :code="newDetail.code" readonly />
        <params-list class="params-list" :params="newDetail.params" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLarge from '@/components/atoms/button-large.vue'
import InputBox from '@/components/molecules/input-box'
import uploadRequirements from './upload-requirements.vue'

import codeBox from '../code.vue'
import ParamsList from '../../customblock-detail/params/params-list'
import versionSelect from '@/components/molecules/version-select'

export default {
  name: 'SaveCustomBlockPopup',
  components: {
    ButtonLarge,
    InputBox,
    uploadRequirements,
    versionSelect,
    codeBox,
    ParamsList
  },
  data() {
    return {
      version: null,
      releaseNote: '',
      requirements: null,
      requirementsError: false
    }
  },
  props: {
    detail: Object,
    newDetail: Object,
    disabledClick: Boolean
  },
  computed: {
    disabledSubmit() {
      if (this.releaseNote === '') return true
      if (this.requirementsError === true) return true

      return false
    }
  },
  methods: {
    saveCustomBlock() {
      const detail = JSON.parse(JSON.stringify(this.newDetail))
      detail.version = this.version
      detail.releaseNote = this.releaseNote
      detail.requirements = this.requirements
      this.$emit('save-customblock', detail)
    },
    setRequirementsError(bool) {
      this.requirementsError = bool
    }
  }
}
</script>

<style lang="scss" scoped>
.save {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(#{adjustVW(618)}, 1fr) adjustVW(934);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-error {
    color: $text-caution;
    font-size: $text-base;
  }
  &-upload {
    display: flex;
    font-size: $text-base;
  }
  &-item {
    margin: 0 0 $space-medium;
  }
  &-detail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      display: grid;
      grid-template-areas: 'codebox params';
      grid-template-columns: 1fr adjustVW(298);
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    .codebox {
      overflow: hidden;
      grid-area: codebox;
      border-right: $border-sub;
    }
    .params-list {
      overflow: hidden;
      grid-area: params;
    }
  }

  &-foot {
    margin-top: auto;
  }
}
</style>
