<template>
  <div>
    <div class="item">
      <div v-tooltip="mixinErrorTips" class="item-half">
        <div v-tooltip.bottom="showTips.value">
          <MoleculesInputBox
            :title="$t('customblock.edit.content.params.detail.default')"
            :value="internalValue.value"
            :min="internalValue.min"
            :max="internalValue.max"
            required
            isGray
            type="number"
            @input="checkValue($event, 'value')"
            @blur="updateValue('value')"
            @focus="mixinToggleFocus('default')"
          />
        </div>
      </div>
    </div>
    <div class="item">
      <div v-tooltip.bottom="mixinErrorTipsMin">
        <div v-tooltip.bottom="showTips.min">
          <MoleculesInputBox
            :title="$t('customblock.edit.content.params.detail.min')"
            :value="internalValue.min"
            :max="internalValue.max"
            :error="mixinIsMinLargerMax"
            type="number"
            isGray
            @input="checkValue($event, 'min')"
            @blur="updateValue('min')"
            @focus="mixinToggleFocus('min')"
          />
        </div>
      </div>
      <div v-tooltip.bottom="mixinErrorTipsMax">
        <div v-tooltip.bottom="showTips.max">
          <MoleculesInputBox
            :title="$t('customblock.edit.content.params.detail.max')"
            :value="internalValue.max"
            :min="internalValue.min"
            :error="mixinIsMaxSmallerMin"
            isGray
            type="number"
            @input="checkValue($event, 'max')"
            @blur="updateValue('max')"
            @focus="mixinToggleFocus('max')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numericParamsSetting from '@/mixin/numeric-params-setting.js'

import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  components: {
    MoleculesInputBox
  },
  mixins: [numericParamsSetting],
  data() {
    return {
      showTipsType: {
        value: false,
        max: false,
        min: false
      }
    }
  },
  computed: {
    showTips() {
      const contentBase = {
        content: this.$t('customblock.edit.content.params.detail.isNotInt'),
        trigger: 'manual',
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 }
      }
      return {
        value: {
          show: this.showTipsType.value,
          ...contentBase
        },
        max: {
          show: this.showTipsType.max,
          ...contentBase
        },
        min: {
          show: this.showTipsType.min,
          ...contentBase
        }
      }
    }
  },
  methods: {
    checkValue(value, type) {
      if (!value || value === 0 || Number.isInteger(value)) {
        this.showTipsType[type] = false
      } else {
        this.showTipsType[type] = true
      }
      this.internalValue[type] = value
    },
    updateValue(type) {
      const target = this.internalValue[type]
      if (!Number.isInteger(target)) {
        this.internalValue[type] = Math.ceil(target)
        this.showTipsType[type] = false
      }
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  grid-column-gap: $space-small;
  &:not(:last-of-type) {
    margin-bottom: $space-medium;
  }
  &-half {
    width: calc(50% - #{$space-small / 2});
  }
}
</style>
