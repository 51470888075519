<template>
  <div class="left-panel-wrap">
    <div class="left-panel-versions">
      <versions
        :detail="detail"
        :history="history"
        :loading="historyLoading"
        @version-click="$emit('version-click', $event)"
      />
    </div>
    <div v-if="!loading" class="left-panel-codebox">
      <codeBox :code="detail.code" readonly />
    </div>
    <div class="left-panel-params">
      <params v-if="!loading" :params="detail.params" />
    </div>
    <div class="left-panel-reqs">
      <requirements :requirements="detail.requirements" />
    </div>
  </div>
</template>

<script>
import params from './params'
import versions from './versions'
import codeBox from '@/components/organisms/customblock-edit/code.vue'
import requirements from './requirements-list-wide.vue'

export default {
  components: {
    params,
    versions,
    codeBox,
    requirements
  },
  props: {
    detail: Object,
    history: Array,

    loading: Boolean,
    historyLoading: Boolean
  }
}
</script>

<style lang="scss" scoped>
$sideWidth: 304;

.left-panel {
  &-wrap {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'versions codebox params'
      'versions reqs params';
    grid-template-columns: adjustVW($sideWidth) minmax(0, 1fr) adjustVW(
        $sideWidth
      );
    grid-template-rows: minmax(0, 1fr) adjustVW(200);
    height: 100%;
    background-color: #f4f8ff;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-versions {
    grid-area: versions;
    background-color: $white;
    box-shadow: $box-shadow-main;
    z-index: 1;
  }
  &-codebox {
    grid-area: codebox;
  }
  &-params {
    grid-area: params;
    background-color: $white;
    box-shadow: $box-shadow-main;
    z-index: 1;
  }
  &-reqs {
    bottom: 0;
    width: calc(100% - #{$space-small * 2});
    margin: $space-small;
    background-color: $white;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: reqs;
  }
}
</style>
