<template>
  <div>
    <texts
      class="title"
      :text="values.name"
      color="gray"
      size="small"
    />
    <label class="input" @click="updateValue(internalNumberValue)">
      <radio-button-base :checked="internalNumberValue === internalValue" />
      <div v-tooltip="errorTips" class="input-inner">
        <MoleculesInputBox
          :value="internalNumberValue"
          :min="values.params.min"
          :max="values.params.max"
          type="number"
          isGray
          @input="updateInputValue"
        />
      </div>
    </label>
    <label
      v-for="(extra, index) in values.params.extra_selections"
      :key="index"
      class="input input-text"
    >
      <radio-button-base
        :value="internalValue"
        :text="extra"
        :name="extra"
        :checked="extra === internalValue"
        @input="updateValue(extra)"
      />
    </label>
  </div>
</template>

<script>
import numericParamPrevie from '@/mixin/numeric-param-preview'
import MoleculesInputBox from '@/components/molecules/input-box'
import radioButtonBase from '@/components/atoms/radio-button-base'

import texts from '@/components/atoms/text'

export default {
  components: {
    radioButtonBase,
    MoleculesInputBox,
    texts
  },
  mixins: [numericParamPrevie],
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalNumberValue: 0,
      internalValue: 0
    }
  },
  watch: {
    'values.params.value'(newVal) {
      this.checkNumberValue(newVal)
      this.internalValue = newVal
    }
  },
  mounted() {
    this.checkNumberValue(this.values.params.value)
    this.internalValue = this.values.params.value
  },
  methods: {
    checkNumberValue(target) {
      if (isNaN(target)) {
        this.internalNumberValue = this.values.params.min ?? 0
      } else {
        this.internalNumberValue = target
      }
    },
    updateValue(value) {
      const res = JSON.parse(JSON.stringify(this.values))
      res.params.value = value
      this.internalValue = value
      this.$emit('input', res)
    },
    updateInputValue(number) {
      if (!isNaN(this.values.params.value)) {
        const res = JSON.parse(JSON.stringify(this.values))
        let resValue = number
        if (isNaN(number)) {
          resValue = null
        }
        res.params.value = resValue
        this.internalValue = resValue
        this.$emit('input', res)
      }
      this.internalNumberValue = number
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-base;
}
.input {
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: $space-small;
  }
  &-inner {
    width: 100%;
  }
  &-text {
    @include button-transition(true);
  }
}
</style>
