<template>
  <div class="customblock-list-wrap">
    <div class="customblock-list-top">
      <customblock-list-top
        :accountInfo="accountInfo"
        :checked="checked"
        :list="list"
        :loading="loading"
        @input-search="checkSearchResult"
        @menu-click="$emit('menu-click', $event)"
        @new-customblock="$emit('new-customblock')"
      />
    </div>
    <div class="customblock-list-table">
      <customblock-list-list
        v-model="checked"
        :list="filteredList"
        :loading="loading"
        :noSearchResults="noSearchResults"
        @sort-label="$emit('sort-label', $event)"
        @show-customblock="$emit('show-customblock', $event)"
      />
    </div>
  </div>
</template>

<script>
import customblockListTop from './top'
import customblockListList from './list'

export default {
  name: 'CustomblockListMain',
  components: {
    customblockListTop,
    customblockListList
  },
  data() {
    return {
      searchResult: null,
      checked: [],
      noSearchResults: false
    }
  },
  props: {
    list: Array,
    loading: Boolean,
    accountInfo: Object
  },
  methods: {
    checkSearchResult(e) {
      this.searchResult = e.result
      this.noSearchResults = e.noSearchResults
    }
  },
  computed: {
    filteredList() {
      return this.searchResult ?? this.list
    }
  }
}
</script>

<style lang="scss" scoped>
.customblock-list-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;
}

.customblock-list-table {
  overflow: hidden;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
}
</style>
