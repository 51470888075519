<template>
  <paramInner :value="value" />
</template>
<script>
import paramInner from '@/components/organisms/customblock-edit/edit-preview/inner'

export default {
  components: {
    paramInner
  },
  data() {
    return {
      value: {
        name:
          this.item?.name != null
            ? `${this.item.name} (${this.name})`
            : this.name,
        type: this.item.type,
        params: this.item.params
      }
    }
  },
  props: {
    name: String,
    item: Object
  }
}
</script>
