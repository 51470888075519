// organisms/edit-params用の設定

export default {
  data() {
    return {
      checkMin: false,
      checkMax: false
    }
  },
  computed: {
    errorTips() {
      const base = {
        content: '',
        trigger: 'manual',
        show: this.checkMin || this.checkMax,
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 }
      }
      if (this.checkMin) {
        base.content =
          this.$t('customblock.edit.range.maxSmallerMin') +
          this.$t('customblock.edit.range.minSmallerMin', {
            min: this.values.params.min
          })
      } else if (this.checkMax) {
        base.content =
          this.$t('customblock.edit.range.minLargerMax') +
          this.$t('customblock.edit.range.maxLargerMax', {
            max: this.values.params.max
          })
      }
      return base
    }
  },
  watch: {
    'values.params.min'(newVal) {
      this.checkMin = false
      if (newVal) {
        this.checkMin = !isNaN(newVal) && this.internalValue < newVal
      }
    },
    'values.params.max'(newVal) {
      this.checkMax = false
      if (newVal) {
        this.checkMax = !isNaN(newVal) && this.internalValue > newVal
      }
    },
    internalValue(newVal) {
      this.checkMax = false
      this.checkMin = false
      const min = this.values.params.min
      const max = this.values.params.max
      if (!newVal || isNaN(newVal)) return
      if (min) {
        this.checkMin = !isNaN(min) && newVal < min
      }
      if (max) {
        this.checkMax = !isNaN(max) && newVal > max
      }
      const checkError = this.checkMax || this.checkMin
      this.$emit('toggle-varidated-test', checkError)
    }
  }
}
