<template>
  <transition name="delete-transition">
    <div v-if="isShow" class="delete-wrap">
      <div class="delete-body">
        <MoleculesTextWithIcon
          class="delete-item"
          icon-name="delete"
          :text="$t('common.delete')"
          color="caution"
          is-button
          size="small"
          @click.native="$emit('confirm-delete')"
        />
        <MoleculesTextWithIcon
          class="delete-item"
          icon-name="close"
          :text="$t('common.cancel')"
          color="gray"
          is-button
          size="small"
          @click.native="$emit('discard-delete')"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import MoleculesTextWithIcon from '@/components/molecules/text-with-icon'
export default {
  components: {
    MoleculesTextWithIcon
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  &-body {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
  }
  &-item {
    cursor: pointer;
    @include buttonHover;
  }
  &-transition {
    &-enter-active {
      transition: opacity $transition-base, transform $transition-base;
    }
    &-leave-active {
      position: absolute;
      opacity: 0;
      transition: transform $transition-base;
    }
    &-enter-from,
    &-leave-from {
      transform: translateX(0);
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateX(100%);
      will-change: opacity, transform;
    }
  }
}
</style>
