<template>
  <describe-of-error
    :description="$t('customblock.popup.notYours.description')"
    :okButton="$t('customblock.popup.notYours.ok')"
    @ok="goBack"
  />
</template>

<script>
import describeOfError from '@/components/organisms/common-popups/describe-of-error'

export default {
  components: {
    describeOfError
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'customblockDetail',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
</script>
