var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-list"},[_vm._l((_vm.menus),function(menu){return [(menu.value !== 'menu')?_c('div',{key:menu.index,staticClass:"menu-button-wrap"},[_c('text-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.tipsContents(menu),
          trigger: menu.disabled ? 'hover' : 'manual',
          show: menu.tips && _vm.activeMenu === menu.value && _vm.tipsFlag,
          autoHide: false,
          delay: { show: 200, hide: 200 }
        }),expression:"{\n          content: tipsContents(menu),\n          trigger: menu.disabled ? 'hover' : 'manual',\n          show: menu.tips && activeMenu === menu.value && tipsFlag,\n          autoHide: false,\n          delay: { show: 200, hide: 200 }\n        }"}],staticClass:"menu-item",attrs:{"text":menu.name,"iconName":menu.iconName,"isDisabled":menu.disabled},on:{"click":function($event){_vm.$emit('menu-click', { activeMenu: menu.value }),
          _vm.menuClick(menu.value)}}})],1):_c('div',{key:menu.index,staticClass:"menu-icon-wrap"},[_c('button',{ref:"menuButton",refInFor:true,staticClass:"menu-icon",on:{"click":function($event){_vm.$emit('menu-click', { activeMenu: 'menu' }), _vm.menuClick('menu')}}},[_c('icons',{attrs:{"iconName":"menu","size":"small"}})],1),(_vm.activeMenu === 'menu')?_c('menu-box',{attrs:{"menus":menu.menu,"open":_vm.activeMenu === 'menu',"menuButton":_vm.$refs.menuButton,"isLeft":"","isBottom":""},on:{"menu-click":function($event){_vm.$emit('menu-click', { activeMenu: $event.activeMenu }),
          _vm.menuClick($event.activeMenu)},"close":function($event){return _vm.menuClick('')}}}):_vm._e()],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }