<template>
  <div>
    <!-- TODO 可能であればトランジッション作る -->
    <div
      v-for="(option, index) in internalValue.options"
      :key="index"
      class="item"
    >
      <div v-tooltip="duplicateTips(index, 'text')">
        <MoleculesInputBox
          v-model="internalValue.options[index].text"
          :title="
            $t('customblock.edit.content.params.detail.displayName') +
              (index + 1)
          "
          required
          isGray
        />
      </div>
      <div v-tooltip="duplicateTips(index, 'value')">
        <MoleculesInputBox
          v-model="internalValue.options[index].value"
          :title="
            $t('customblock.edit.content.params.detail.value') + (index + 1)
          "
          required
          isGray
        />
      </div>
      <div class="item-check">
        <MoleculesTransitionToggleContents>
          <div v-if="deleteTarget !== index">
            <div class="title">
              <texts
                :text="$t('customblock.edit.content.params.detail.initial')"
                size="small"
                color="gray"
              />
              <div v-if="internalValue.options.length > 1" class="item-icon">
                <AtomsIcons
                  color="caution"
                  icon-name="delete"
                  is-button
                  size="small"
                  @icon-click="checkDelete(index)"
                />
              </div>
              <div v-else class="item-icon" />
            </div>
            <div class="input">
              <AtomsCheckBox
                v-model="option.check"
                :is-disabled="!option.value"
              />
            </div>
          </div>
          <MoleculesDeleteParams
            v-else
            :is-show="deleteTarget === index"
            @confirm-delete="confirmDelete(index)"
            @discard-delete="discardDelete"
          />
        </MoleculesTransitionToggleContents>
      </div>
    </div>
    <button
      class="item-add"
      :class="{
        'item-disabled': disabledAdd
      }"
      :disabled="disabledAdd"
      @click="disabledAdd ? '' : addBox()"
    >
      <MoleculesTextWithIcon
        size="small"
        icon-name="plus"
        :text="$t('customblock.edit.content.params.detail.add')"
        :color="disabledAdd ? 'gray' : 'black'"
      />
    </button>
  </div>
</template>

<script>
import { duplicateObjectValueCheck } from '@/lib/misc'

import AtomsIcons from '@/components/atoms/icon'
import AtomsCheckBox from '@/components/atoms/checkbox-base'
import MoleculesInputBox from '@/components/molecules/input-box'
import MoleculesTextWithIcon from '@/components/molecules/text-with-icon'
import MoleculesDeleteParams from '@/components/molecules/delete-params/delete-params'
import MoleculesTransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import texts from '@/components/atoms/text'

export default {
  components: {
    texts,
    AtomsIcons,
    AtomsCheckBox,
    MoleculesInputBox,
    MoleculesTextWithIcon,
    MoleculesDeleteParams,
    MoleculesTransitionToggleContents
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      deleteTarget: null
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    },
    disabledAdd() {
      return this.internalValue.options.some((option) => {
        return !option.value || !option.text
      })
    }
  },
  watch: {
    'internalValue.options': {
      handler(newVal) {
        const checkError = newVal.some((option) => {
          return !option.value || !option.text
        })
        const checkDuplicateText = duplicateObjectValueCheck(newVal, 'text')
        const checkDuplicateValue = duplicateObjectValueCheck(newVal, 'value')
        const error = checkError || checkDuplicateText || checkDuplicateValue
        this.$emit('set-error', error)
      },
      deep: true
    }
  },
  methods: {
    checkDelete(index) {
      this.deleteTarget = index
    },
    confirmDelete(index) {
      this.internalValue.options.splice(index, 1)
      this.deleteTarget = null
    },
    discardDelete() {
      this.deleteTarget = null
    },
    addBox() {
      const defaultIndex = this.internalValue.options.length + 1
      this.internalValue.options.push({
        value: 'default_' + defaultIndex,
        text:
          this.$t('customblock.edit.content.params.detail.defaultValueText') +
          defaultIndex,
        check: false
      })
    },
    duplicateTips(index, type) {
      const target = this.internalValue.options[index][type]
      const check = this.internalValue.options.some((option, optionIndex) => {
        return (
          option[type] === target &&
          index !== optionIndex &&
          index > optionIndex
        )
      })
      return {
        content: this.$t(
          'customblock.edit.content.params.detail.isNoDuplicate'
        ),
        trigger: 'manual',
        hideOnTargetClick: true,
        delay: { show: 300, hide: 300 },
        show: check
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  grid-column-gap: $space-small;
  margin-bottom: $space-sub;
}
.input {
  width: fit-content;
}
.item {
  display: flex;
  grid-column-gap: $space-small;
  &:not(:last-of-type) {
    padding-bottom: $space-small;
    margin-bottom: $space-small;
    border-bottom: $border-sub;
  }
  &-check {
    flex-shrink: 0;
  }
  &-add {
    width: fit-content;
    margin-top: $space-medium;
    cursor: pointer;
    @include buttonHover;
  }
  &-disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
