<template>
  <div>
    <texts
      class="title"
      color="gray"
      :text="values.name"
      size="small"
    />
    <div class="input">
      <div
        v-for="(option, index) in values.params.options"
        :key="index"
        class="input-inner"
      >
        <AtomsCheckBox
          class="input-item"
          :value="option.check"
          :checked="option.check"
          :text="option.text"
          @input="updateValue($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomsCheckBox from '@/components/atoms/checkbox-base'
export default {
  components: {
    AtomsCheckBox
  },
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateValue(value, index) {
      const res = JSON.parse(JSON.stringify(this.values))
      res.params.options[index].check = value
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-base;
}
.input {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: $space-small;
  grid-row-gap: $space-small;
  &-item {
    padding-right: 0;
    margin: 0;
  }
}
</style>
