<template>
  <span
    v-if="!['Input', 'SqlInput'].includes(layer.type)"
    class="block"
    :class="{ 'block-used': isUsed }"
  >
    <span class="content">
      {{
        $t('preprocessing.layerNames.' + layer.type) + '（' + cropColumn + '）'
      }}
    </span>
  </span>
  <span v-else class="block" :class="{ 'block-used': isUsed }">
    <span class="content">
      {{ $t('preprocessing.layerNames.' + layer.type) }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    /** 前処理のブロック */
    layer: Object,
    /** 前処理を適用済みかどうか */
    isUsed: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  computed: {
    cropColumn: function () {
      if (this.layer.column.length > 8) {
        return this.layer.column.substr(0, 8) + '…'
      }
      return this.layer.column
    }
  }
}
</script>

<style lang="scss" scoped>
.block {
  padding: $space-text $space-sub adjustVW(3);
  background: $background-decoration;
  line-height: adjustVW(30);
  text-align: center;
  vertical-align: middle;
  border-radius: 9in;
  &-used {
    background: $border-gray;
  }
}

.content {
  color: $text-decoration;
  font-size: $text-min;
  word-break: break-all;
  white-space: inherit;
  .block-used & {
    color: $text-func;
  }
}
</style>
