<template>
  <div>
    <texts
      class="title"
      :text="values.name"
      color="gray"
      size="small"
    />
    <div class="input">
      <MoleculesSelectBox
        :value="internalValue"
        :items="values.params.options"
        :placeholder="$t('common.placeholderForSelect')"
        isGray
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import MoleculesSelectBox from '@/components/molecules/select-box'

export default {
  components: {
    MoleculesSelectBox
  },
  props: {
    values: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: ''
    }
  },
  watch: {
    'values.params': {
      handler(newVal) {
        this.internalValue = newVal.value
      },
      deep: true
    }
  },
  mounted() {
    this.internalValue = this.values.params.value
  },
  methods: {
    updateValue(value) {
      const res = JSON.parse(JSON.stringify(this.values))
      res.params.value = value
      this.internalValue = value
      this.$emit('input', res)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: $space-text;
}
</style>
