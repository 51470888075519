<template>
  <!--
    カスタムブロック詳細の上部
    @click="$emit('add-new-data')" 新規カスタムブロック登録ポップアップを表示
  -->
  <page-top
    :title="detail.name"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="top-function-wrap">
      <div class="top-function-main">
        <button-main
          class="top-function-button"
          :text="$t('customblock.button.editCustomblock')"
          type="sub"
          :is-disabled="!writable"
          @click="$emit('edit-customblock')"
        />
        <div v-if="!writable">
          <texts
            class="caution-text"
            :text="$t('customblock.popup.notYours.description')"
            size="min"
            color="caution"
          />
        </div>
      </div>
      <text-menu-list
        class="top-function-menu"
        :menus="menuButtons"
        checked
        isMenuShow
        @menu-click="$emit('menu-click', $event)"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import textMenuList from '@/components/molecules/text-menu-list'

export default {
  components: {
    pageTop,
    buttonMain,
    textMenuList
  },
  props: {
    detail: Object,
    writable: Boolean
  },
  computed: {
    menuButtons() {
      return [
        {
          id: 0,
          name: this.$t('customblock.menu.downloadLog'),
          iconName: 'download',
          value: 'downloadLog',
          disabled: !this.detail.has_log,
          disabledTips: this.$t('customblock.noLogs')
        },
        {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          disabled: !this.writable,
          disabledTips: this.$t(
            'common.disabled.organization.otherAccountItemDelete'
          )
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-menu {
    margin-left: auto;
  }
  &-main {
    display: flex;
    align-items: center;
  }
}

.caution-text {
  white-space: pre-line;
}
</style>
