<template>
  <div class="processing">
    <loading />
  </div>
</template>

<script>
import loading from '@/components/atoms/loading.vue'

export default {
  components: {
    loading
  }
}
</script>

<style lang="scss" scoped>
.processing {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: $z-loading;
}
</style>
