<template>
  <div>
    <div class="item">
      <MoleculesInputBox
        v-model="internalValue.value"
        :title="$t('customblock.edit.content.params.detail.default')"
        isGray
      />
    </div>
  </div>
</template>

<script>
import MoleculesInputBox from '@/components/molecules/input-box'

export default {
  components: {
    MoleculesInputBox
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      internalValue: {}
    }
  },
  mounted() {
    this.internalValue = this.value
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
}
</style>
