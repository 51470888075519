<template>
  <div
    v-if="!checkNoItem"
    class="conditions-list"
    :class="{
      'conditions-list-separate': vertical
    }"
  >
    <template v-if="checkColumn">
      <dot-text
        :maxWidth="maxWidth"
        :text="checkColumn"
        color="green"
        size="min"
      />
      <texts
        :text="$t('trainedAi.optimization.conditions.separater')"
        size="min"
        color="gray"
      />
    </template>
    <template v-if="checkExpression">
      <dot-text
        :maxWidth="maxWidth"
        :text="checkExpression"
        color="green"
        size="min"
      />
      <texts
        :text="$t('trainedAi.optimization.conditions.separater')"
        size="min"
        color="gray"
      />
    </template>
    <template v-if="checkConditions">
      <dot-text :maxWidth="maxWidth" color="emphasis" size="min">
        <div class="conditions-item">
          <texts
            v-for="(target, index) in checkConditions"
            :key="index"
            class="conditions-item-text"
            :class="{
              'conditions-item-text-shrink': !target.shrink
            }"
            :text="target.text"
            size="min"
            color="emphasis"
          />
        </div>
      </dot-text>
    </template>
    <template v-if="checkInteger">
      <dot-text
        :maxWidth="maxWidth"
        :text="$t('trainedAi.optimization.conditions.integer')"
        color="emphasis"
        size="min"
      />
    </template>
  </div>
  <div v-else-if="showNoItem" class="conditions-list">
    <dot-text
      :maxWidth="maxWidth"
      :text="$t('trainedAi.optimization.conditions.notSet')"
      size="min"
      color="gray"
    />
  </div>
</template>

<script>
import dotText from '@/components/atoms/dot-text'

export default {
  components: {
    dotText
  },
  props: {
    conditionsItem: Object,
    noColumn: Boolean,
    showNoItem: Boolean,
    maxWidth: {
      type: Number,
      default: 200
    },
    vertical: Boolean
  },
  computed: {
    checkInteger() {
      if (!this.conditionsItem) return null
      return this.conditionsItem?.integer
    },
    checkExpression() {
      if (!this.conditionsItem) return null
      return this.conditionsItem?.expression ?? null
    },
    checkConditions() {
      if (!this.conditionsItem) return null
      const target = this.conditionsItem?.condition?.type ?? null
      const params = this.conditionsItem?.condition?.params
      if (!target) return null
      if (target === 'closer') {
        const main = {
          text: params.target,
          shrink: true
        }
        const desc = {
          text: this.$t('trainedAi.optimization.conditions.closerParams'),
          shrink: false
        }
        if (this.$i18n.locale === 'ja') {
          return [main, desc]
        } else {
          return [desc, main]
        }
      } else {
        return [
          {
            text: this.$t('trainedAi.optimization.conditions.' + target),
            shrink: false
          }
        ]
      }
    },
    checkColumn() {
      if (!this.conditionsItem || this.noColumn) return null
      return this.conditionsItem?.column ?? null
    },
    checkNoItem() {
      return (
        !this.checkInteger && !this.checkExpression && !this.checkConditions
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.conditions {
  &-list {
    display: flex;
    align-items: center;
    grid-column-gap: $space-base;
    &-separate {
      flex-wrap: wrap;
      grid-row-gap: $space-base;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    &-text {
      &-shrink {
        flex-shrink: 0;
      }
    }
  }
}
</style>
