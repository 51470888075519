<template>
  <div class="page-not-found">
    <images class="page-not-found-image" imageName="pageNotFound" />
    <card-base class="page-not-found-inner">
      <texts
        class="page-not-found-text"
        :text="$t('common.pageNotFound.text')"
        isShowAll
      />
      <div>
        <button-main
          :text="$t('common.pageNotFound.backButton')"
          type="emphasis"
          @click="back"
        />
        <button-main
          :text="
            projectId != null
              ? $t('common.trainLeave.popup.leaveButton')
              : $t('common.trainLeave.popup.leaveButtonProject')
          "
          type="sub"
          @click="backTop"
        />
      </div>
    </card-base>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'
import images from '@/components/atoms/images'
import CardBase from '@/components/atoms/card-base.vue'
export default {
  components: {
    texts,
    buttonMain,
    images,
    CardBase
  },
  methods: {
    back() {
      this.$router.back()
    },
    backTop() {
      if (this.projectId != null) {
        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        this.$router.push({ name: 'projectList' })
      }
    }
  },
  computed: {
    projectId() {
      if (this.$route?.params?.projectId == null) return null
      return parseInt(this.$route.params.projectId)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
  background: $background-sub;
  &-image {
    width: 100%;
    height: auto;
    opacity: 0.5;
  }
  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    background: $background;
    transform: translate(-50%, -50%);
  }
  &-text {
    margin-bottom: $space-small;
  }
}
</style>
