export async function loadFile(/** @type {File} */ file) {
  const reader = new FileReader()
  /** @type {Promise<ProgressEvent<FileReader>>} */
  const fileReaderPromise = new Promise((resolve, reject) => {
    reader.addEventListener('load', resolve)
    reader.addEventListener('error', () => reject(new Error('FILE_READ_ERROR')))
    reader.addEventListener('abort', () =>
      reject(new Error('FILE_READ_ABORTED'))
    )
  })
  reader.readAsArrayBuffer(file)
  await fileReaderPromise
  /** @type {ArrayBuffer} */
  const body = reader.result
  return body
}
