<template>
  <!--
    カスタムブロック一覧の上部
    @click="$emit('new-customblock')" 新規カスタムブロック登録ポップアップを表示
  -->
  <page-top
    :title="$t('customblock.listTitle')"
    iconName="customblock"
    notScroll
  >
    <div class="top-function-wrap">
      <transition-toggle-contents>
        <div key="datasetList" class="top-function-inner">
          <div>
            <button-main
              class="top-function-button"
              :text="$t('customblock.button.addNewCustomblock')"
              type="emphasis"
              @click="$emit('new-customblock')"
            />
          </div>

          <fuse-search
            v-if="!loading"
            class="top-function-search"
            :placeholder="$t('customblock.searchCustomBlock')"
            :value.sync="list"
            :option="option"
            @input="$emit('input-search', $event)"
          />
        </div>
      </transition-toggle-contents>
      <div class="top-function-menus">
        <div class="top-function-menus-inner">
          <menu-list
            :checked="checked"
            :menus="iconMenus"
            :isMenuShow="checked.length > 0"
            @menu-click="menuClick"
          />
        </div>
      </div>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import fuseSearch from '@/components/molecules/fuse-search'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import menuList from '@/components/molecules/menu-list'

export default {
  components: {
    pageTop,
    buttonMain,
    fuseSearch,
    transitionToggleContents,
    menuList
  },
  data() {
    return {
      toggleStatus: false,
      filterText: '',
      internalAccountIdFilter: '',
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'author_id', // カスタムブロックのみaccountId ではなく author_idである点に注意
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    accountInfo: Object,

    checked: Array,
    loading: Boolean,
    list: Array
  },
  methods: {
    menuClick(event) {
      const resultEvent = {
        name: event.activeMenu,
        checked: this.checked
      }
      this.$emit('menu-click', resultEvent)
    }
  },
  computed: {
    checkDelete() {
      const targets = this.checked.map((x) => {
        return this.list.find((item) => item.customblock_id === x)
      })
      const notYours = targets.some(
        (item) => item.author_id !== this.accountInfo.accountId
      )
      if (notYours && this.accountInfo?.organizationInfo?.role !== 'admin')
        return this.$t('common.disabled.organization.otherAccountItemDelete')

      return null // adminの場合と、自分のデータセットだけならOK
    },
    checkEdit() {
      const targets = this.checked.map((x) => {
        return this.list.find((item) => item.customblock_id === x)
      })
      const notYours = targets.some(
        (item) => item.author_id !== this.accountInfo.accountId
      )
      if (notYours && this.accountInfo?.organizationInfo?.role !== 'admin')
        return this.$t('common.disabled.organization.otherAccountItem')

      return null
    },
    checkLog() {
      const targets = this.checked.map((x) => {
        return this.list.find((item) => item.customblock_id === x)
      })
      return targets?.has_log ?? false
    },
    iconMenus() {
      return [
        {
          id: 0,
          iconName: 'download',
          value: 'download',
          isMultiple: false,
          disabled: !this.checkLog,
          tips: {
            name: this.$t('customblock.menu.downloadLog'),
            disabledName: this.$t('customblock.noLogs')
          }
        },
        {
          id: 1,
          iconName: 'edit', // edit
          value: 'edit',
          isMultiple: false,
          disabled: this.checkEdit,
          tips: {
            name: this.$t('customblock.menu.edit'),
            disabledName: this.checkEdit
          }
        },
        {
          id: 2,
          iconName: 'delete', // delete
          value: 'delete',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.checkDelete,
          tips: {
            name: this.$t('customblock.menu.delete'),
            disabledName: this.checkDelete
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-inner {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-menus {
    display: flex;
    align-items: center;
    &-inner {
      padding: 0 $space-small 0 0;
      margin: 0 $space-small 0 0;
      border-right: $border-main;
    }
  }
  &-free {
    display: flex;
    align-items: center;
    &-message {
      white-space: pre-line;
    }
    &-link {
      color: $text-caution;
      text-decoration: underline;
    }
  }
}
</style>
