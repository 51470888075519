<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :cautionTexts="$t('customblock.edit.notSaveLeave')"
    />
    <customblockEdit
      :detail="detail"
      :loadingDetail="loadingDetail"
      :notFound="notFound"
      :popup="popup"
      :validatorState="validatorState"
      :waitPageLoading="waitPageLoading"
      :waitProcessLoading="waitProcessLoading"
      @save-click="clickSave"
      @save-customblock="saveCustomblock"
      @validation-change="updateValidator"
      @close-modal="closePopup"
      @select-type="selectType"
      @editted="editted"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customblockEdit from '@/components/templates/customblock-edit.vue'
import { duplicateValidator } from '../lib/validator/customblock'
import preventLeave from '@/components/molecules/prevent-leave'
import setMountedTimer from '@/mixin/set-mounted-timer'

const sampleCodes = {
  classification: require('@/lib/samplecode/classification.py'),
  regression: require('@/lib/samplecode/regression.py'),
  convert_dataset: require('@/lib/samplecode/convert.py'),
  acquire_dataset: require('@/lib/samplecode/acquire.py')
}

export default {
  name: 'CustomBlockEditView',
  mixins: [setMountedTimer],
  components: {
    customblockEdit,
    preventLeave
  },
  data() {
    return {
      loadingDetail: false,
      notFound: false,
      detail: {
        code: '',
        params: {},
        requirements: {},
        block_type: null
      },
      popup: {
        showPopup: [],
        newDetail: {
          code: '',
          params: {},
          requirements: {},
          blockType: ''
        }
      },
      validatorState: {
        duplicate: true,
        suggest: []
      },
      isPrevent: false,
      saveLoading: false,
      pageLoading: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.pageLoading = true
      await vm.fetchAccountInfo()
      if (vm.accountInfo.plan !== 'basic') {
        vm.showPopup('freePlan')
        return
      }
      const id = vm.$route.params.id
      if (id != null) {
        await vm.fetchDetail(id)
        if (vm.notFound) {
          return false
        } else if (!vm.writable) {
          vm.showPopup('notYours')
        }
      } else {
        vm.loadingDetail = true
        vm.showPopup('selectType')
        await vm.fetchCustomblocks()
      }
      vm.pageLoading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$refs.preventLeave.prevent) {
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('customblock', ['customblockList', 'loading', 'submitting']),
    ...mapGetters('auth', ['accountInfo']),
    isNew() {
      return this.$route.params.id == null
    },
    writable() {
      if (this.isNew) {
        return true
      }
      const notYours = this.detail.author_id !== this.accountInfo.accountId
      return !(notYours && this.accountInfo?.organizationInfo?.role !== 'admin')
    },
    waitPageLoading() {
      return this.loadingDetail || this.pageLoading
    },
    waitProcessLoading() {
      return this.saveLoading
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('customblock', ['fetchCustomblocks']),
    editted() {
      this.isPrevent = true
    },
    async fetchDetail(id) {
      this.loadingDetail = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getCustomBlockDetail',
          customBlockId: id
        })
        if (res.status === 'error') {
          this.notFound = true
          return
        }
        this.detail = res.result
        this.popup.newDetail.requirements = this.detail.requirements
        this.popup.newDetail.blockType = this.detail.block_type
      } finally {
        this.loadingDetail = false
      }
    },
    clickSave(e) {
      const id = this.$route.params.id
      this.popup.newDetail.code = e.code
      this.popup.newDetail.params = e.params
      if (id != null) {
        this.showPopup('save')
      } else {
        this.showPopup('saveNew')
      }
    },
    updateValidator({ name }) {
      this.validatorState = duplicateValidator(this.customblockList, name)
    },
    async saveCustomblock(e) {
      this.saveLoading = true
      const id = this.$route.params.id
      const base = {
        code: this.popup.newDetail.code,
        params: this.popup.newDetail.params,
        requirements: e.requirements ?? this.popup.newDetail.requirements,
        block_type: this.detail.block_type
      }
      if (id != null) {
        const res = await this.$sendMessageAndReceive({
          action: 'releaseCustomBlock',
          customBlockId: id,
          newVersion: e.version,
          release_note: e.releaseNote,
          ...base
        })
        if (res.status === 'error') {
          throw res
        }
        this.isPrevent = false
        this.$nextTick(() => {
          this.$router.push({
            name: 'customblockList'
          })
        })
      } else {
        const res = await this.$sendMessageAndReceive({
          action: 'createCustomBlock',
          name: e.name,
          description: e.description,
          ...base
        })
        if (res.status === 'error') {
          throw res
        }
        this.isPrevent = false
        this.$nextTick(() => {
          this.$router.push({
            name: 'customblockList'
          })
        })
      }
      this.saveLoading = false
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      if (e === 'selectType') {
        if (this.detail.block_type == null) {
          this.$router.back()
        }
      }
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    selectType(e) {
      this.detail.block_type = e
      this.detail.code = sampleCodes[e]
      this.loadingDetail = false
      this.closePopup('selectType')
    }
  }
}
</script>
